import { API_URL } from "./Config"
import { GenericMessage } from "./Descriptors"
import { Utils } from "./Utils"


export interface Tag {
	id: number
	name: string
}

export interface GetAllTagsResponse {
	tags: Tag[]
}

export interface CreateTagRequest {
	name: string
}

export class TagAPI {
	utils: Utils
	tagsUrl: string

	constructor() {
		this.utils = new Utils()
		this.tagsUrl = `${API_URL}/tags`
	}


	async getAll() {
		return await this.utils.apiCall<GetAllTagsResponse>(`${this.tagsUrl}/`)
	}

	async getOne(id: number) {
		return await this.utils.apiCall<Tag>(`${this.tagsUrl}/${id}/}`)
	}

	async delete(id: number) {
		return await this.utils.deleteApiCall<GenericMessage>(`${this.tagsUrl}/${id}/`)
	}

	async createOne(payload: CreateTagRequest) {
		return await this.utils.postApiCallWithPayload(`${this.tagsUrl}/`, payload)
	}
}