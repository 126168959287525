import React from "react";
import { FC, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { GetAllTagsResponse, TagAPI } from "../api/Tag";
import { NewTagModal } from "./NewTagModal";
import { SelectableButton } from "./SelectableButton";

export type TagSelectorModalProps = {
  show: boolean;
  hide: () => void;
  setSelectedTags: (selected: string[]) => void;
};

export const TagSelectorModal: FC<TagSelectorModalProps> = (props) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tags, setTags] = useState<GetAllTagsResponse>(
    {} as GetAllTagsResponse
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [showNewTagModal, setShowNewTagModal] = useState(false);

  const fetchTags = async () => {
    try {
      const t = await new TagAPI().getAll();
      setTags(t);
    } catch (err) {
      console.log(err);
    }
  };

  const removeFromSelected = (tag: string) => {
    setSelectedTags((prevState) => {
      const index = prevState.indexOf(tag);
      if (index <= -1) {
        return prevState;
      }
      prevState.splice(index, 1);
      return prevState;
    });
  };

  const addToSelected = (tag: string) => {
    setSelectedTags((prevState) => {
      const index = prevState.indexOf(tag);
      if (index > -1) {
        return prevState;
      }
      prevState.push(tag);
      return prevState;
    });
  };

  useEffect(() => {
    props.setSelectedTags(selectedTags);
  }, [props, selectedTags]);

  useEffect(() => {
    fetchTags();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tags === undefined || tags.tags === undefined) {
      setIsLoaded(false);
    } else {
      setIsLoaded(true);
    }
  }, [tags]);

  if (!isLoaded) {
    return <Spinner style={{ margin: "1rem" }} animation={"border"} />;
  }
  return (
    <>
      <NewTagModal
        hide={() => setShowNewTagModal(false)}
        show={showNewTagModal}
        closeCallback={() => fetchTags()}
      />
      <Modal show={props.show} onHide={() => props.hide()}>
        <Modal.Body>
          {tags.tags.map((tag) => {
            return (
              <SelectableButton
                key={tag.id}
                text={tag.name}
                style={{ margin: "0.25rem" }}
                whenSelected={() => addToSelected(tag.name)}
                whenDeselected={() => removeFromSelected(tag.name)}
                initialSelectState={
                  selectedTags.indexOf(tag.name) > -1 ? true : false
                }
              />
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowNewTagModal(true)}>
            Címke létrehozása
          </Button>
          <Button variant="success" onClick={() => props.hide()}>
            Mentés
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
