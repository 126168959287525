import React from "react";
import { FC, useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  ClipAPI,
  CreateClipRequest,
  CreateMultipleClipsRequest,
} from "../api/Clips";
import { NewClipDataInputForm } from "./NewClipDataInputForm";

export type EnrichedNewClipRequestElement = {
  id: number;
  start_ts: string;
  end_ts: string;
  tags: string[];
  video_url: string;
};

export const NewClipsCreator: FC<{}> = () => {
  const [inputFormIds, setInputFormIds] = useState<number[]>([1]);
  const [lastId, setLastId] = useState(1);
  const [enrichedClips, setEnrichedClips] = useState<
    EnrichedNewClipRequestElement[]
  >([] as EnrichedNewClipRequestElement[]);
  const [videoUrl, setVideoUrl] = useState("");
  const navigate = useNavigate();

  const addNewInputForm = () => {
    setInputFormIds((prev) => {
      let newId = prev[prev.length - 1] + 1;
      prev.push(newId);
      setLastId(newId);
      return prev;
    });
  };

  const addOrUpdateClip = (
    id: number,
    start_ts: string,
    end_ts: string,
    tags: string[]
  ) => {
    setEnrichedClips((prevState) => {
      // remove clip with the id
      let ecIdx = prevState.findIndex((e) => e.id === id);
      if (ecIdx !== -1) {
        prevState.splice(ecIdx, 1);
      }

      // add the updated/new element
      prevState.push({
        id: id,
        start_ts: start_ts,
        end_ts: end_ts,
        tags: tags,
        video_url: videoUrl,
      });

      return prevState;
    });
  };

  const signalFilledState = (id: number) => {
    if (id !== lastId) return;
    addNewInputForm();
  };

  const handleSaveTagsClick = () => {
    let clips = {
      clips: [] as CreateClipRequest[],
    } as CreateMultipleClipsRequest;

    enrichedClips.forEach((ec) => {
      let c = {
        start_ts: ec.start_ts,
        end_ts: ec.end_ts,
        video_url: ec.video_url,
        tags: ec.tags,
      } as CreateClipRequest;

      clips.clips.push(c);
    });

    new ClipAPI().createMultipleClips(clips);
    navigate("/");
  };

  return (
    <Container style={{ margin: "1rem" }}>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Youtube URL</Form.Label>
              <Form.Control
                id="videoUrl"
                value={videoUrl}
                placeholder="https://www.youtube.com/watch?v=5nOFh_Pin4U"
                onChange={(e) => {
                  setVideoUrl(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      {inputFormIds.map((id) => {
        return (
          <Row style={{ margin: "1rem" }} key={id}>
            <NewClipDataInputForm
              filledCallback={() => signalFilledState(id)}
              clipUpdateCallback={(ss: string, es: string, t: string[]) =>
                addOrUpdateClip(id, ss, es, t)
              }
            />
          </Row>
        );
      })}
      <br />
      <Row>
        <Col md={4}></Col>
        <Col md={4}>
          <Button variant="success" onClick={() => handleSaveTagsClick()}>
            Mentés
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
