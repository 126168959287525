import React from "react";
import { FC } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"


type TeamInfo = {
	name: string,
	logoPath: string,
	trainingTimes: string[],
	location: { name: string, exact: string }
}

export const Teams: FC<{}> = () => {
	const teamInfos: TeamInfo[] = [
		{
			name: "Egyszusz VSE",
			logoPath: "/egyszusz_logo/180x180.png",
			trainingTimes: ["Hétfő 19:30-21:30", "Szerda 19:30-21:30"],
			location: {
				name: "Duna Aréna",
				exact: "Budapest, Népfürdő u. 36"
			}
		},
		{
			name: "Pécsi Piranha",
			logoPath: "/piranha_logo/300x238.png",
			trainingTimes: ["Kedd 20:00-21:00", "Csütörtök 21:00-22:00", "Vasárnap 18:00-20:00"],
			location: {
				name: "Hullámfürdő",
				exact: "Pécs, Szendrey Júlia u. 7"
			}
		},
	]

	return (
		<Container className="p-5" >
			<Row>
				{
					teamInfos.map(teamInfo => {
						return (

							<Col key={teamInfo.name}>
								<Card style={{ width: '18rem' }} bg="info" text="light" >
									{/* <Card.Img variant="top" src={teamInfo.logoPath} /> */}
									<Card.Header>{teamInfo.name}</Card.Header>
									<Card.Body>
										<br />
										<Card.Text><b>Edzések:<br /></b>
											{teamInfo.trainingTimes.map(time => {
												return <b key={time}>{time}<br /></b>
											})}
										</Card.Text>
										<br />
										<Card.Text><b>Helyszín:<br /></b>{teamInfo.location.name}<br />{teamInfo.location.exact}</Card.Text>
									</Card.Body>
								</Card>
							</Col>
						)
					})
				}
			</Row>
		</Container>
		// <div class="our-teams">
		// 	<div class="title is-1">Egyszusz VSE</div>
		// 	<div class="title is-3">Edzések</div>
		// 	<div class="title is-5">
		// 		<p>
		// 			Duna Aréna<br />Budapest, Népfürdő u. 36, 1138<br />Hétfő, szerda
		// 			19:30-21:30
		// 		</p>
		// 	</div>
		// 	<div class="title is-1">Pécsi Piranha</div>
		// 	<div class="title is-3">Edzések</div>
		// 	<div class="title is-5">
		// 		<p>
		// 			Hullámfürdő<br />Pécs, Szendrey Júlia u. 7, 7623<br />Szerda, csütörtök
		// 			20:00-22:00<br />
		// 			Vasárnap 17:00-19:00
		// 		</p>
		// 	</div>
		// </div>
	)
}