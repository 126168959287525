import React, { createContext, useContext } from "react";
import { LoginRequest, LoginResponse, WhoAmIResponse } from "../api/Descriptors";
import { useQuery, useQueryClient } from "react-query";
import { UsersClient } from "../api/Users";

type UserContext = {
  user: WhoAmIResponse | undefined;
  error: unknown;
  loading: boolean;
  isAdmin: boolean;
  isLoggedIn: boolean;
  isClubManager: boolean;
  logout: () => void;
  login: (payload: LoginRequest) => Promise<LoginResponse>;
}

const UserContext = createContext<UserContext | undefined>(undefined);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const userClient = new UsersClient()
  const queryClient = useQueryClient()

  const { data: user, isLoading: loading, error } = useQuery({
    queryKey: "who-am-i",
    queryFn: userClient.getWhoAmI
  })

  const logout = () => {
    localStorage.removeItem('token');
    queryClient.invalidateQueries("who-am-i")
  }

  const login = async (payload: LoginRequest) => {
    const response = await userClient.login(payload)
    queryClient.invalidateQueries("who-am-i")
    return response
  }

  const isLoggedIn = user !== undefined && user.id !== 0
  const isAdmin = isLoggedIn && (user?.roles?.includes("admin") || false)
  const isClubManager = isLoggedIn && (user?.roles?.includes("club_manager") || false)

  return (
    <UserContext.Provider value={{ user, loading, error, isAdmin, isClubManager, isLoggedIn, logout, login}}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error("useUser must be used whithin userProvider")
  }
  return context;
}
