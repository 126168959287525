import ky, { Options } from "ky";
import { KyInstance } from "ky/distribution/types/ky";
import { API_URL } from "./Config";
import {
  GenericMessage,
  NewPlayerInput,
  PlayerIdNameMap,
  PlayerInfo,
  WinningPercentageInfo,
  PairingInfo,
  NewPlayerResponse,
} from "./Descriptors";
import { getToken } from "./Login";

export class PlayersClient {
  api: KyInstance;

  constructor() {
    this.api = ky.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            if (getToken()) {
              request.headers.set("Authorization", `Bearer ${getToken()}`);
            }
          },
        ],
      },
    });
  }

  getIdNameMap = async (): Promise<PlayerIdNameMap> => {
    return await this.api.get(`${API_URL}/players/id-name-pairs/`).json();
  };

  getAll = async ({status}: {status?: "active" | "inactive"}): Promise<PlayerInfo[]> => {
    const options: Options = {
      searchParams: {
        ...(status ? {status} : {})
      }
    }
    return await this.api.get(`${API_URL}/players/`, options).json();
  };

  getActive = async (): Promise<PlayerInfo[]> => {
    return this.getAll({status: "active"})
  };

  getWinningPercentageStats = async (): Promise<WinningPercentageInfo> => {
    return await this.api.get(`${API_URL}/players/win-stats/`).json();
  };

  createNew = async (player: NewPlayerInput): Promise<NewPlayerResponse> => {
    return await this.api.post(`${API_URL}/players/`, { json: player }).json();
  };

  getSingle = async (playerId: string): Promise<PlayerInfo> => {
    return await this.api.get(`${API_URL}/players/${playerId}/`).json();
  };

  updateSingle = async (
    playerId: string,
    player: NewPlayerInput
  ): Promise<GenericMessage> => {
    return await this.api
      .put(`${API_URL}/players/${playerId}/`, { json: player })
      .json();
  };

  getPairingForPlayer = async (playerId: string): Promise<PairingInfo> => {
    return await this.api
      .get(`${API_URL}/players/pairings/${playerId}/`)
      .json();
  };
}
