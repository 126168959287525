import { Button } from "@mantine/core";
import { useState } from "react";
import { CreatePushSubscription } from "../../api/Descriptors";
import { NotificationAPI } from "../../api/PushNotification";
import { AntonText } from "../../util/text";
import { IoIosNotifications } from "react-icons/io";

const api = new NotificationAPI();

const createPushSubscription = async (
  pushSubscription: CreatePushSubscription
) => {
  const response = await api.createPushSubscription(pushSubscription);
  return response;
};

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+") // eslint-disable-line
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const askPermission = async () => {
  const result = await new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission(resolve);

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });

  console.log(result);

  return result === "granted";
};

const registerSW = async () => {
  const registration = await navigator.serviceWorker.register(
    "/service-worker.js"
  );
  await navigator.serviceWorker.ready;
  return registration;
};

const setupPushNotifications = async (_askPermission: boolean = false) => {
  if (Notification.permission !== "granted") {
    if (_askPermission) {
      await askPermission();
    } else {
      return "error";
    }
  }
  const registration = await registerSW();
  console.log("vapid public key", process.env.VAPID_PUBLIC_KEY);
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(
      "BO_zPgZXuKvZ_rEbpQq5UTuDx7aGiASRJr4y9WLSb0NxrnOVoawvTtJ74XWdVPfgjzvh2VdiJ3DIKF3siZJqhmQ"
    ),
  };

  console.log("subscribeOptions", subscribeOptions);
  console.log("subscribing to push manager");
  const subscription = await registration.pushManager.subscribe(
    subscribeOptions
  );
  console.log("subscription successful");
  console.log(subscription.toJSON());

  //@ts-ignore
  await createPushSubscription(subscription.toJSON());

  return "success";
};

type RegisterServiceWorkerButtonProps = {
  setRegistrationState: (state: boolean) => void;
};

export const RegisterServiceWorkerButton = (
  props: RegisterServiceWorkerButtonProps
) => {
  // const { profile } = useAuth();
  const [state, setState] = useState("ready");

  const onEnable = async () => {
    setState("loading");
    const result = await setupPushNotifications(true);
    setState(result);
    props.setRegistrationState(true);
  };

  // useEffectOnce(() => {
  //   if (profile) {
  //     setupPushNotifications();
  //   }
  // });

  return (
    <Button
      loading={state === "loading"}
      color={state === "error" ? "red" : "blue"}
      onClick={onEnable}
      leftIcon={(state !== "loading" && <IoIosNotifications />) || null}
      w={"40rem"}
    >
      {state !== "loading" && AntonText("Értesítések bekapcsolása")}
    </Button>
  );
};
