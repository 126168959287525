import React from "react";
import { FC, useEffect, useState } from "react";
import { Alert, Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { TagSelectorModal } from "./TagSelectorModal";


type TimeStampAlertProps = {
	errors: string[]
}

const TimeStampAlert: FC<TimeStampAlertProps> = (props) => {
	return (
		<>
			<Alert variant="danger" show={props.errors.length === 0 ? false : true}>
				<p>
					{props.errors.join(". ")}
				</p>
			</Alert>
		</>
	)
}

export type ClipData = {
	startingTimeStamp: string
	endingTimeStamp: string
	tags: string[]
}

type NewClipDataInputFormProps = {
	filledCallback: () => void
	clipUpdateCallback: (start_ts: string, end_ts: string, tags: string[]) => void
}

export const NewClipDataInputForm: FC<NewClipDataInputFormProps> = (props) => {
	const [showTagSelectorModal, setShowTagSelectorModal] = useState(false)
	const [selectedTags, setSelectedTags] = useState<string[]>(["Hello", "World"])
	const [startingTimeStamp, setStartingTimeStamp] = useState("")
	const [endingTimeStamp, setEndingTimeStamp] = useState("")
	const [formatErrors, setFormatErrors] = useState<string[]>([])

	const addToFormatErrors = (text: string) => {
		setFormatErrors(prevState => {
			const index = prevState.indexOf(text)
			if (index > -1) {
				return prevState
			}
			prevState.push(text)
			return prevState
		})
	}

	const emptyFormatErrors = () => {
		setFormatErrors([])
	}

	const validateTimeStamp = (inputField: string) => {
		emptyFormatErrors()
		const parts = inputField.split(":")
		if (parts.length !== 2) {
			addToFormatErrors("Nem helyes formátum (mm:ss)")
		} else if (parts[0].length < 1) {
			addToFormatErrors("Nincs megadva perc")
		} else if (parts[1].length < 1) {
			addToFormatErrors("Nincs megadva másodperc")
		}

		let mins = parseInt(parts[0])
		if (isNaN(mins) || mins < 0) addToFormatErrors("A megadott perc nem pozitív egész szám")
		let secs = parseInt(parts[1])
		if (isNaN(secs) || secs < 0) addToFormatErrors("A megadott másodperc nem pozitív egész szám")
		if (secs > 59) addToFormatErrors(`A megadott másodperc nagyobb, mint 59 (${secs})`)
	}

	useEffect(() => {
		if (startingTimeStamp !== "" && endingTimeStamp !== "" && formatErrors.length === 0) {
			props.filledCallback()
			props.clipUpdateCallback(startingTimeStamp, endingTimeStamp, selectedTags)
		}
	}, [startingTimeStamp, endingTimeStamp, props, formatErrors, selectedTags])


	return (
		<>
			<TagSelectorModal
				show={showTagSelectorModal}
				hide={() => setShowTagSelectorModal(false)}
				setSelectedTags={(selected) => setSelectedTags(selected)}
			/>
			<Form>
				<Row>
					<TimeStampAlert errors={formatErrors} />
				</Row>
				<Row>
					<Col >
						<InputGroup>
							<Form.Control
								id="startTime"
								value={startingTimeStamp}
								onChange={(e) => {
									validateTimeStamp(e.target.value)
									setStartingTimeStamp(e.target.value)
								}}
								placeholder="01:23"
							/>
							<InputGroup.Text>-tól</InputGroup.Text>
						</InputGroup>
					</Col>
					<Col >
						<InputGroup>
							<Form.Control
								id="startTime"
								value={endingTimeStamp}
								onChange={(e) => {
									validateTimeStamp(e.target.value)
									setEndingTimeStamp(e.target.value)
								}}
								placeholder="02:34"
							/>
							<InputGroup.Text>-ig</InputGroup.Text>
						</InputGroup>
					</Col>
					<Col>
						<Button variant="primary" onClick={
							() => {
								setShowTagSelectorModal(true)
							}
						}>
							+ Címke
						</Button>
					</Col>
					<Col className="text-start">
						Címkék: {selectedTags.join(", ")}
					</Col>
				</Row>
			</Form>
		</>
	)
}