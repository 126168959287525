import React from "react";
import { FC, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ScorerDataUpdate } from "../api/Descriptors";
import { GoalScorerAPI } from "../api/GoalScorer";
import { PlayersClient } from "../api/Players";
import { TrainingClient } from "../api/Trainings";
import { GoalInputField } from "../components/GoalInputField";
import { usePlayers } from "../hooks/usePlayers";

type ScoreInfo = {
	name: string
	id: number,
	score: number,
	color: string
}

type ScoreInputParams = {
	trainingId: string
}

export const ScoreInput: FC<{}> = () => {
	const navigate = useNavigate()
	const {} = usePlayers()
	const { trainingId } = useParams<ScoreInputParams>()
	const [scores, setScores] = useState<ScoreInfo[]>([])

	const fetchPresentPlayers = async () => {
		if (!trainingId) {
			console.log("did not receive training id, aborting")
			return
		}

		try {
			const trainingData = await new TrainingClient().getSingleTraining(trainingId)
			const playerIdNameMap = await new PlayersClient().getIdNameMap()
			if (!trainingData || !playerIdNameMap) {
				console.log("could not retrieve necessary data (training or player id-name map)")
				return
			}

			let scoreInfos = [] as ScoreInfo[]
			trainingData.white_team.forEach(playerId => {
				scoreInfos.push({
					name: `${playerIdNameMap[playerId].lname} ${playerIdNameMap[playerId].fname}`,
					id: playerId,
					score: 0,
					color: "white"
				})
			})
			trainingData.black_team.forEach(playerId => {
				scoreInfos.push({
					name: `${playerIdNameMap[playerId].lname} ${playerIdNameMap[playerId].fname}`,
					id: playerId,
					score: 0,
					color: "black"
				})
			})

			setScores(scoreInfos)

		} catch (reason) {
			throw reason
		}
	}

	useEffect(() => {
		fetchPresentPlayers()
		//eslint-disable-next-line
	}, [])

	const changeScore = (id: number, value: number) => {
		setScores(prevScoreState => {
			let newState = [] as ScoreInfo[]
			prevScoreState.forEach(scoreInfo => {
				let newInfo = { ...scoreInfo }
				if (newInfo.id === id) {
					const newValue = newInfo.score + value
					if (newValue >= 0) {
						newInfo.score = newValue
					}
				}
				newState.push(newInfo)
			})
			return newState
		})
	}

	const incrementScore = (id: number) => {
		return () => {
			changeScore(id, 1)
		}
	}

	const decrementScore = (id: number) => {
		return () => {
			changeScore(id, -1)
		}
	}

	const updateData = async () => {
		let payload = {
			"training_id": trainingId,
			"scorers": {} as { [id: number]: number }
		} as ScorerDataUpdate

		scores.forEach(scoreInfo => {
			payload.scorers[scoreInfo.id] = scoreInfo.score
		})

		if (!trainingId) {
			console.log("can not update as trainingId is missing")
			return
		}

		try {
			await new GoalScorerAPI().update(payload)
			navigate(`/trainings/${trainingId}/strength-values`)
		} catch (reason) {
			alert(`nem sikerült a góllövők listájának a frissítése ${reason}`)
		}
	}

	const cancel = () => {
		navigate(`/trainings/${trainingId}`)
	}

	useEffect(() => {
		fetchPresentPlayers()
		// eslint-disable-next-line
	}, [])

	return (
		<div>
			<Container fluid="sm">
				{
					scores.map(score => {
						return <GoalInputField
							key={score.id}
							name={score.name}
							score={score.score}
							color={score.color}
							decrementer={decrementScore(score.id)}
							incrementer={incrementScore(score.id)} />
					})
				}
			</Container>
			<Button variant="success" onClick={updateData} style={{ margin: "1rem" }}>Frissítés</Button>
			<Button variant="danger" onClick={cancel} style={{ margin: "1rem" }}>Mégse</Button>
		</div>
	)
}
