import { API_URL } from "./Config"
import { GenericMessage } from "./Descriptors"
import { Tag } from "./Tag"
import { Utils } from "./Utils"


export interface ClipResponse {
	id: number
	start_ts: string
	end_ts: string
	video_url: string
	tags: Tag[]
}

export interface GetAllClipsResponse {
	clips: ClipResponse[]
}

export interface CreateClipRequest {
	start_ts: string
	end_ts: string
	video_url: string
	tags: string[]
}

export interface CreateMultipleClipsRequest {
	clips: CreateClipRequest[]
}

export class ClipAPI {
	utils: Utils
	clipsUrl: string

	constructor() {
		this.utils = new Utils()
		this.clipsUrl = `${API_URL}/clips`
	}

	async getAllClips() {
		return await this.utils.apiCall<GetAllClipsResponse>(`${this.clipsUrl}/`)
	}

	async createOneClip(payload: CreateClipRequest) {
		return await this.utils.postApiCallWithPayload(`${this.clipsUrl}/`, payload)
	}

	async deleteOneClip(id: number) {
		return await this.utils.deleteApiCall<GenericMessage>(`${this.clipsUrl}/${id}/`)
	}

	async getOneClip(id: number) {
		return await this.utils.apiCall<ClipResponse>(`${this.clipsUrl}/${id}/`)
	}

	async createMultipleClips(clips: CreateMultipleClipsRequest) {
		return await this.utils.postApiCallWithPayload(`${this.clipsUrl}/multi/`, clips)
	}
}