import { useQuery } from "react-query";
import { UsersClient } from "../../api/Users"
import { Loader, Table } from "@mantine/core";

export const UsersList = () => {
    const usersClient = new UsersClient()
    const { error, data, isLoading } = useQuery({
        queryKey: "users",
        queryFn: () => usersClient.getAllUsers(),
    });

    if (error) {
        return <h2>Nem sikerült betölteni a felhasználókat az adatbázisból</h2>;
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <Table withBorder withColumnBorders striped>
                <thead>
                    <tr>
                        <th>
                            Azonosító
                        </th>
                        <th>
                            Név
                        </th>
                        <th>
                            Felhasználónév
                        </th>
                        <th>
                            Strava azonosító
                        </th>
                        <th>
                            Játékos azonosító
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.data.map(user => {
                            return (
                                <tr key={user.strava_id}>
                                    <td>
                                        <a href={`/users/${user.id}`}>{user.id}</a>
                                    </td>
                                    <td>
                                        {`${user.strava_last_name} ${user.strava_first_name}`}
                                    </td>
                                    <td>
                                        {user.username}
                                    </td>
                                    <td>
                                        {user.strava_id}
                                    </td>
                                    <td>
                                        {user.player_id}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </>
    )
}