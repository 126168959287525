import React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { NewTrainingDataRequest } from "../api/Descriptors";
import { TrainingClient } from "../api/Trainings";
import { WhiteBlackBlueButton } from "./WhiteBlackBlueButton";
import { usePlayers } from "../hooks/usePlayers";
import { Loader } from "@mantine/core";

type PlayerInfo = {
  id: number;
  name: string;
  state: number;
};

export const NewTrainingInput = () => {
  const { useAllPlayers } = usePlayers()
  const colorVariants = ["secondary", "light", "dark", "info"];
  const { data: playersData, error, isLoading} = useAllPlayers({status: "active"})
  const [players, setPlayers] = useState<PlayerInfo[]>([])
  const navigate = useNavigate();

  useEffect(() => {
    const playersList: PlayerInfo[] = playersData ? playersData.map(p => {
      return {
        id: p.id,
        name: `${p.fname} ${p.lname}`,
        state: 0
      }
    }) : []
    setPlayers(playersList)
  }, [playersData])

  const getPlayerById = (players: PlayerInfo[], id: number) => {
    for (let i = 0; i < players.length; i++) {
      if (players[i].id === id) {
        return players[i];
      }
    }
    return null;
  };

  const updatePlayerState = (playerId: number, newState: number) => {
    setPlayers((prevState) => {
      const player = getPlayerById(prevState, playerId);
      if (!player) {
        return prevState;
      }
      player.state = newState;
      return prevState;
    });
  };

  const specializedUpdatePlayer = (id: number) => {
    return (newState: number) => {
      updatePlayerState(id, newState);
    };
  };

  const logState = async () => {
    const payload = {
      black_team: [],
      white_team: [],
      swimming_players: [],
    } as NewTrainingDataRequest;

    players.forEach((player) => {
      switch (player.state) {
        case 1:
          payload.white_team.push(player.id);
          break;
        case 2:
          payload.black_team.push(player.id);
          break;
        case 3:
          payload.swimming_players.push(player.id);
          break;
        default:
          break;
      }
    });

    try {
      const resp = await new TrainingClient().createNewTraining(payload);
      if (resp.status === "success") {
        navigate(`/trainings/${resp.id}/score`);
      }
    } catch (error) {
      navigate("/login");
    }
  };

  if (error) {
    return <h2>{`Nem sikerült betölteni a játékosokat`}</h2>;
  } 
  if (isLoading) {
    return <Loader />
  }
  
    return (
      <Container>
        {players.map((player) => {
          return (
            <WhiteBlackBlueButton
              key={player.id}
              name={player.name}
              style={{ margin: "0.5rem" }}
              colorVariants={colorVariants}
              stateCallback={specializedUpdatePlayer(player.id)}
            />
          );
        })}
        <Col>
          <Button
            variant="success"
            onClick={logState}
            style={{ margin: "0.5rem" }}
          >
            Bevitel
          </Button>
        </Col>
      </Container>
    );
};
