import React from "react";
import { FC, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { PlayerIdNameMap, TrainingData } from "../api/Descriptors";
import { TrainingClient } from "../api/Trainings";
import { usePlayers } from "../hooks/usePlayers";
import { Loader } from "@mantine/core";
import { useTrainings } from "../hooks/useTrainings";

type SingleTrainigPlayerInforTableProps = {};

type SingleTrainigPlayerInforTableURLParams = {
  trainingId: string;
};

export const SingleTrainingPlayerInfoTable: FC<
  SingleTrainigPlayerInforTableProps
> = () => {
  let { trainingId } = useParams<SingleTrainigPlayerInforTableURLParams>();
  const navigate = useNavigate();

  let [alertMessage, setAlertMessage] = useState("");
  const { useIdNameMap } = usePlayers()
  const { useTraining } = useTrainings()
  const { data: idNameMap, error: errorINM, isLoading: idNameMapLoading } = useIdNameMap()
  const { data: trainingData, error: errorTraining, isLoading: isLoadingTraining } = useTraining(trainingId!)

  const handleEditClick = () => {
    navigate(`/trainings/${trainingId}/score`);
  };

  const clearAlertMessage = () => {
    setAlertMessage("");
  };

  const handleDeleteClick = async () => {
    if (trainingId === undefined) {
      return;
    }
    let tId = parseInt(trainingId);
    try {
      let resp = await new TrainingClient().deleteTraining(tId);
      if (resp.status === "fail") {
        setAlertMessage("Az edzést nem lehetett sajnos törölni!");
        throw new Error(resp.message);
      }
      navigate("/trainings?pageNumber=1&pageSize=10");
    } catch (reason) {
      console.log(reason);
    }
  };


  if (errorTraining || errorINM) {
    return (
      <h2>{"Could not fetch training or id-name map"}</h2>
    );
  }

  if (isLoadingTraining || idNameMapLoading) {
    return <Loader />;
  }

  if (alertMessage) {
    return (
      <div>
        <Container>
          <Row>
            <Col></Col>
            <Col>
              <Alert
                style={{ margin: "1rem" }}
                variant="danger"
                onClose={() => clearAlertMessage()}
                dismissible
              >
                <Alert.Heading>Jaj!</Alert.Heading>
                <p>{alertMessage}</p>
              </Alert>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Table bordered style={{ margin: "1rem" }}>
              <TrainingPlayerInfoHeader />
              <TrainingBody idNameMap={idNameMap!} trainingData={trainingData!} />
            </Table>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <Button
                variant="success"
                style={{ margin: "5px" }}
                onClick={handleEditClick}
              >
                Szerkesztés
              </Button>
              <Button variant="danger" onClick={() => handleDeleteClick()}>
                Törlés
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <Container>
      <Row>
        <Table bordered style={{ margin: "1rem" }}>
          <TrainingPlayerInfoHeader />
          <TrainingBody idNameMap={idNameMap!} trainingData={trainingData!} />
        </Table>
      </Row>
      <Row>
        <Col></Col>
        <Col>
          <Button
            variant="success"
            style={{ margin: "5px" }}
            onClick={handleEditClick}
          >
            Szerkesztés
          </Button>
          <Button variant="danger" onClick={() => handleDeleteClick()}>
            Törlés
          </Button>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

type TrainingBodyProps = {
  trainingData: TrainingData;
  idNameMap: PlayerIdNameMap;
};
const TrainingBody: FC<TrainingBodyProps> = (props) => {
  const { trainingData, idNameMap } = props
  return (
    <tbody>
      {trainingData.white_team.map((playerId) => {
        const pId = playerId.toString();
        const player = idNameMap[pId];
        const name = `${player.lname} ${player.fname}`;
        const scored = trainingData.white_scorers[playerId]
          ? trainingData.white_scorers[playerId]
          : 0;
        return (
          <TrainingPlayerInfoRow
            color="white"
            id={playerId}
            name={name}
            scored={scored}
            key={pId}
          />
        );
      })}
      {trainingData.black_team.map((playerId) => {
        const pId = playerId.toString();
        const player = idNameMap[pId];
        const name = `${player.lname} ${player.fname}`;
        const scored = trainingData.black_scorers[playerId]
          ? trainingData.black_scorers[playerId]
          : 0;
        return (
          <TrainingPlayerInfoRow
            color="black"
            id={playerId}
            name={name}
            scored={scored}
            key={pId}
          />
        );
      })}
      {trainingData.swimming_players.map((playerId) => {
        const pId = playerId.toString();
        const player = idNameMap[pId];
        const name = `${player.lname} ${player.fname}`;
        return (
          <TrainingPlayerInfoRow
            color="blue"
            id={playerId}
            name={name}
            scored={0}
            key={pId}
          />
        );
      })}
    </tbody>
  );
};

const TrainingPlayerInfoHeader: FC<{}> = () => {
  return (
    <thead>
      <tr>
        <th>ID</th>
        <th>Név</th>
        <th>Gól#</th>
      </tr>
    </thead>
  );
};

type TrainingPlayerInfoRowProps = {
  id: number;
  name: string;
  color: "black" | "white" | "blue";
  scored: number;
};

const TrainingPlayerInfoRow: FC<TrainingPlayerInfoRowProps> = (props) => {
  const rowStyle = () => {
    if (props.color === "black") {
      return "table-dark";
    } else if (props.color === "white") {
      return "table-light";
    } else if (props.color === "blue") {
      return "table-primary";
    }
  };

  return (
    <tr className={rowStyle()}>
      <td>{props.id}</td>
      <td>{props.name}</td>
      <td>{props.scored}</td>
    </tr>
  );
};
