import React from "react";
import { FC } from "react";
import { Button } from "react-bootstrap";

type PlusMinusButtonProps = {
	doPlus: () => void,
	doMinus: () => void
}

export const PlusMinusButton: FC<PlusMinusButtonProps> = (props) => {
	const handlePlusClick = () => {
		props.doPlus()
	}

	const handleMinusClick = () => {
		props.doMinus()
	}

	return (
		<div>
			<Button variant="success" onClick={handlePlusClick}>+</Button>
			{" "}
			<Button variant="danger" onClick={handleMinusClick}>-</Button>
		</div>
	)
}