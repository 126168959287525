import { Loader, Table } from "@mantine/core";
import React, { Suspense } from "react";
import { FC, useEffect, useState } from "react";
import { TrainingData } from "../api/Descriptors";
import { useTrainings } from "../hooks/useTrainings";
import { usePlayers } from "../hooks/usePlayers";
import { useScorers } from "../hooks/useScorers";

type ScoredGamesOfPlayers = {
  [playerId: number]: number[];
};

type SumGoalsForPlayers = {
  [playerId: number]: number;
};

type SortingCategory = {
  value: "goalAvarage" | "name" | "goalNumber" | "gamesPlayedWithScore";
  reversed: boolean;
};

type Aggregated = {
  id: number;
  name: string;
  gamesPlayed: number;
  avgGoals: number;
  sumGoals: number;
};

export const GoalScoringStatisticsTable: FC<{}> = () => {
  const { useAllTrainings } = useTrainings()
  const { useAllPlayers } = usePlayers()
  const { useGetAllScorers } = useScorers()

  let [scoredGamesForPlayers, setScoredGamesForPlayers] =
    useState<ScoredGamesOfPlayers>({} as ScoredGamesOfPlayers);
  let [sumGoalsForPlayers, setSumGoalsForPlayers] =
    useState<SumGoalsForPlayers>({} as SumGoalsForPlayers);
  let [aggData, setAggData] = useState<Aggregated[]>([] as Aggregated[]);
  let [sortedField, setSortedField] = useState<SortingCategory>({
    value: "name",
    reversed: false,
  });

  const { error: errorT, data: trainings, isLoading: isLoadingT } = useAllTrainings({ pageSize: 999, pageNumber: 1 })
  const { error: errorS, data: scorers, isLoading: isLoadingS } = useGetAllScorers()
  const { error: errorP, data: players, isLoading: isLoadingP } = useAllPlayers({ status: "active" });

  useEffect(() => {
    if (!trainings) {
      return;
    }
    let scoredGamesForPlayers: ScoredGamesOfPlayers = {};
    trainings.forEach((training) => {
      if (!hasScoringData(training)) {
        return;
      }
      let players = [...training.white_team, ...training.black_team];

      players.forEach((playerId) => {
        if (
          Object.keys(scoredGamesForPlayers).indexOf(playerId.toString()) === -1
        ) {
          scoredGamesForPlayers[playerId] = [];
        }
        scoredGamesForPlayers[playerId].push(training.id);
      });
    });
    setScoredGamesForPlayers(scoredGamesForPlayers);
  }, [trainings]);

  useEffect(() => {
    if (!scorers) {
      return;
    }
    let sumGoalsForPlayers: { [pid: number]: number } = {};
    scorers.forEach((entry) => {
      if (sumGoalsForPlayers[entry.player_id] === undefined) {
        sumGoalsForPlayers[entry.player_id] = 0;
      }
      sumGoalsForPlayers[entry.player_id] += entry.scored;
    });

    setSumGoalsForPlayers(sumGoalsForPlayers);
  }, [scorers]);

  const hasScoringData = (training: TrainingData) => {
    return training.black_score !== 0 || training.white_score !== 0;
  };

  const sortBy = (aggregatedData: Aggregated[], category: SortingCategory) => {
    switch (category.value) {
      case "goalAvarage":
        if (category.reversed) {
          aggregatedData.sort((a, b) => a.avgGoals - b.avgGoals);
        } else {
          aggregatedData.sort((a, b) => b.avgGoals - a.avgGoals);
        }
        break;

      case "goalNumber":
        if (category.reversed) {
          aggregatedData.sort((a, b) => a.sumGoals - b.sumGoals);
        } else {
          aggregatedData.sort((a, b) => b.sumGoals - a.sumGoals);
        }
        break;

      case "gamesPlayedWithScore":
        if (category.reversed) {
          aggregatedData.sort((a, b) => a.gamesPlayed - b.gamesPlayed);
        } else {
          aggregatedData.sort((a, b) => b.gamesPlayed - a.gamesPlayed);
        }
        break;

      case "name":
        if (category.reversed) {
          aggregatedData.sort((a, b) => {
            if (a.name > b.name) return -1;
            if (a.name < b.name) return 1;
            return 0;
          });
        } else {
          aggregatedData.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!players) {
      return;
    }

    const avargeGoalsPerTraining = (playerId: number) => {
      let numScoredGames = scoredGamesForPlayers[playerId].length;
      let goalsScored = sumGoalsForPlayers[playerId];
      if (numScoredGames === undefined || goalsScored === undefined) {
        return 0.0;
      }
      return goalsScored / numScoredGames;
    };

    let res: Aggregated[] = [];

    players.forEach((pi) => {
      if (!scoredGamesForPlayers[pi.id]) {
        return;
      }
      res.push({
        id: pi.id,
        name: `${pi.lname} ${pi.fname}`,
        sumGoals: sumGoalsForPlayers[pi.id],
        avgGoals: avargeGoalsPerTraining(pi.id),
        gamesPlayed: scoredGamesForPlayers[pi.id].length,
      });
    });

    sortBy(res, sortedField);

    setAggData(res);
  }, [players, scoredGamesForPlayers, sumGoalsForPlayers, sortedField]);

  if (errorP || errorS || errorT) {
    return <h1 style={{ margin: "1rem" }}>Nem sikerült betölteni az oldalt</h1>;
  }

  if (isLoadingS || isLoadingT || isLoadingP) {
    return <Loader />
  }

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Table withBorder striped highlightOnHover withColumnBorders>
          <thead>
            <tr>
              <th
                onClick={() =>
                  setSortedField((prev) => {
                    if (prev.value === "name") {
                      return { value: "name", reversed: !prev.reversed };
                    }
                    return { value: "name", reversed: false };
                  })
                }
              >
                Név
              </th>
              <th
                onClick={() =>
                  setSortedField((prev) => {
                    if (prev.value === "gamesPlayedWithScore") {
                      return {
                        value: "gamesPlayedWithScore",
                        reversed: !prev.reversed,
                      };
                    }
                    return { value: "gamesPlayedWithScore", reversed: false };
                  })
                }
              >
                Edzésszám gólnaplóval
              </th>
              <th
                onClick={() =>
                  setSortedField((prev) => {
                    if (prev.value === "goalNumber") {
                      return { value: "goalNumber", reversed: !prev.reversed };
                    }
                    return { value: "goalNumber", reversed: false };
                  })
                }
              >
                Gólszám
              </th>
              <th
                onClick={() =>
                  setSortedField((prev) => {
                    if (prev.value === "goalAvarage") {
                      return { value: "goalAvarage", reversed: !prev.reversed };
                    }
                    return { value: "goalAvarage", reversed: false };
                  })
                }
              >
                Gólátlag
              </th>
            </tr>
          </thead>
          <tbody>
            {aggData.map((entry) => {
              return (
                <tr key={entry.id}>
                  <td>{entry.name}</td>
                  <td>{entry.gamesPlayed}</td>
                  <td>{entry.sumGoals}</td>
                  <td>{entry.avgGoals.toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Suspense>
    </>
  );
};
