import { useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Image,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  Button,
  ButtonProps,
  NavLink,
  NavLinkProps,
  Group,
  Footer,
} from "@mantine/core";
import { Outlet, useNavigate } from "react-router";
import { NotificationRegistrationButton } from "./notification/NotificationRegistrationButton";
import { AntonText } from "../util/text";
import { useUser } from "../context/UserContext";

export const isLoggedIn = () => {
  return localStorage.getItem("token") ? true : false;
};


export const MainNavbar = () => {
  type AuthButtonProps = {} & ButtonProps;

  const { isAdmin, isClubManager, user, error, isLoggedIn,logout } = useUser()


  const AuthButton = (props: AuthButtonProps) => {
    const navigate = useNavigate();
    if (isLoggedIn) {
      return (
        <>
          <Button
            {...props}
            variant="filled"
            color="red"
            size="sm"
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            {AntonText("Kijelentkezés")}
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            {...props}
            variant="filled"
            color="green"
            size="sm"
            onClick={() => {
              navigate("/login");
            }}
          >
            {AntonText("Bejelentkezés")}
          </Button>
        </>
      );
    }
  };

  type NavLinkButtonProps = {
    onClick: () => void;
  } & ButtonProps;

  const NavLinkButton = (props: NavLinkButtonProps) => {
    return (
      <Button
        {...props}
        variant="subtle"
        color={"gray"}
        size="sm"
        fullWidth
        mt={2}
        styles={(_theme) => ({
          root: {
            backgroundColor: navlinkBgColor,
            color: buttonTextColor,
            "&:hover": {
              color: buttonTextOnHover,
              backgroundColor: navlinkBgColorOnHover,
            },
            fontFamily: "Anton",
            letterSpacing: "0.15rem",
          },
        })}
        onClick={() => {
          setOpened(false);
          props.onClick();
        }}
      >
        {props.children}
      </Button>
    );
  };

  type MyNavLinkProps = {} & NavLinkProps;

  const MyNavLink = (props: MyNavLinkProps) => {
    return (
      <NavLink
        {...props}
        label={
          <Text
            fw={900}
            style={{
              fontFamily: "Anton",
              letterSpacing: "0.15rem",
            }}
          >
            {props.label}
          </Text>
        }
        //active
        variant="subtle"
        color={"gray"}
        styles={(_theme) => ({
          root: {
            backgroundColor: navlinkBgColor,
            color: buttonTextColor,
            borderRadius: 4,
            "&:hover": {
              color: buttonTextOnHover,
              backgroundColor: navlinkBgColorOnHover,
            },
          },
        })}
      >
        {props.children}
      </NavLink>
    );
  };

  const theme = useMantineTheme();
  //const ownGraySchema = theme.colors["own-gray"];
  const ownYellowSchema = theme.colors["own-yellow"];
  const ownBlueSchema = theme.colors["own-blue"];

  //const backGroundColor = ownGraySchema[0];
  const navbarColor = ownBlueSchema[5];
  const navlinkBgColor = navbarColor;
  const navlinkBgColorOnHover = navbarColor;
  const buttonTextColor = "white";
  const buttonTextOnHover = ownYellowSchema[3];

  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();

  const getCurrentWeekNumber = () => {
    const currentDate = new Date();
    const januaryFirst = new Date(currentDate.getFullYear(), 0, 1);
    const daysToNextMonday =
      januaryFirst.getDay() === 1 ? 0 : (7 - januaryFirst.getDay()) % 7;
    const nextMonday = new Date(
      currentDate.getFullYear(),
      0,
      januaryFirst.getDate() + daysToNextMonday
    );

    return currentDate < nextMonday
      ? 52
      : currentDate > nextMonday
        ? Math.ceil(
          (currentDate.getTime() - nextMonday.getTime()) /
          (24 * 3600 * 1000) /
          7
        )
        : 1;
  };

  if (error) {
    console.log("error")
  }

  return (
    <AppShell
      styles={{
        main: {
          background: "#F5F5F5",
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 300, lg: 350 }}
          bg={navbarColor}
        >
          {isLoggedIn ? (
            <MyNavLink label="Sportunkról">
              <NavLinkButton onClick={() => navigate("/about/history")}>
                Története
              </NavLinkButton>
              <NavLinkButton onClick={() => navigate("/about/rules")}>
                Szabályok
              </NavLinkButton>
              <NavLinkButton onClick={() => navigate("/about/hungarian-teams")}>
                Csapatok
              </NavLinkButton>
              {
                /*
                  <NavLinkButton onClick={() => navigate("/about/results")}>
                    Eredmények
                  </NavLinkButton>
                */
              }
            </MyNavLink>
          ) : (
            <>
              <NavLinkButton onClick={() => navigate("/about/history")}>
                Története
              </NavLinkButton>
              <NavLinkButton onClick={() => navigate("/about/rules")}>
                Szabályok
              </NavLinkButton>
              <NavLinkButton onClick={() => navigate("/about/hungarian-teams")}>
                Csapatok
              </NavLinkButton>
            </>
          )}

          {isLoggedIn && (
            <MyNavLink label="Statisztika" mt={10}>
              <NavLinkButton
                onClick={() => navigate("/trainings?pageSize=10&pageNumber=1")}
              >
                Edzések
              </NavLinkButton>
              {(isAdmin || isClubManager) &&
                <NavLinkButton onClick={() => navigate("/players")}>
                  Játékosok
                </NavLinkButton>
              }
              <NavLinkButton onClick={() => navigate("/stats/goal")}>
                Gól
              </NavLinkButton>
              <NavLinkButton
                onClick={() => navigate("/stats/winning-percentage")}
              >
                Győzelmi arány
              </NavLinkButton>
            </MyNavLink>
          )}

          {isLoggedIn &&
            <MyNavLink label="Kihívás" mt={10}>
              <NavLinkButton onClick={() => navigate("/challenge/periods")}>
                Összesítő
              </NavLinkButton>
              <NavLinkButton
                onClick={() =>
                  navigate(`/challenge/weekly?week=${getCurrentWeekNumber()}`)
                }
              >
                Heti eredmények
              </NavLinkButton>
            </MyNavLink>
          }

          {isAdmin && (
            <MyNavLink label="Beállítások" mt={10}>
              <NavLinkButton onClick={() => navigate("/users")}>
                Felhasználók
              </NavLinkButton>
            </MyNavLink>
          )}

          {(isAdmin || isClubManager) && (
            <MyNavLink label="Új" mt={10}>
              <NavLinkButton onClick={() => navigate("/trainings/new")}>
                Edzés
              </NavLinkButton>
              <NavLinkButton onClick={() => navigate("/players/new")}>
                Játékos
              </NavLinkButton>
              <NavLinkButton onClick={() => navigate("/events/new")}>
                Esemény
              </NavLinkButton>
            </MyNavLink>
          )}

          {isLoggedIn && (
            <MyNavLink label="Egyéb" mt={10}>
              <NavLinkButton onClick={() => navigate("/events")}>
                Események
              </NavLinkButton>
              <NavLinkButton onClick={() => navigate("/team-picker")} mt={10}>
                Csapatválasztó
              </NavLinkButton>
            </MyNavLink>
          )}

          <Group mt={"auto"}>
            {isLoggedIn ? <NotificationRegistrationButton /> : null}
            <AuthButton w={"40rem"} />
          </Group>
        </Navbar>
      }
      header={
        <Header
          height={{ base: 70, md: 70 }}
          p="md"
          bg={navbarColor}
        // withBorder={false}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <MediaQuery smallerThan={"sm"} styles={{ display: "none" }}>
              <Image
                src={"/logo.png"}
                width={50}
                height={50}
                ml={"sm"}
                radius={"xl"}
                onClick={() => {
                  navigate("/");
                }}
              />
            </MediaQuery>
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <>
                <Text
                  maw={"auto"}
                  m={"auto"}
                  size={28}
                  fw={700}
                  color={ownYellowSchema[3]}
                  style={{
                    fontFamily: "Anton",
                    letterSpacing: "0.15rem",
                  }}
                >
                  Egyszusz VSE
                </Text>
                {isLoggedIn && (
                  <Text
                    size={14}
                    fw={700}
                    color={ownYellowSchema[3]}
                    style={{
                      fontFamily: "Anton",
                      letterSpacing: "0.15rem",
                    }}
                  >
                    {user
                      ? user.firstname && user.lastname
                        ? `${user.lastname} ${user.firstname}`
                        : user.username
                      : ""}
                  </Text>
                )}
              </>
            </MediaQuery>
          </div>
        </Header>
      }
      footer={
        <Footer height={50} bg={navbarColor}>
          <Image
            src={"powered_by_strava_1.svg"}
            width={200}
            style={{
              marginLeft: "auto",
            }}
          />
        </Footer>
      }
    >
      <Outlet />
    </AppShell>
  );
};
