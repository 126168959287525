import {
  Button,
  Container,
  Flex,
  Loader,
  Table,
  Text,
} from "@mantine/core";
import { WeeklyTrainingStatsClient } from "../api/WeeklyTrainingStats";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import { GetListActivitiesForAthleteResponse, SportType, WeeklyActivityReportsResponse } from "../api/Descriptors";
import { useMemo } from "react";
import { Column, useSortBy, useTable } from "react-table";
import { OrderableTable } from "../components/base/orderable-table";
import { useSearchParams } from "react-router-dom";
import { RiArrowLeftDoubleLine, RiArrowRightDoubleLine } from "react-icons/ri";
import { useUser } from "../context/UserContext";


const convertModifiedTime = (modified_time: number) => {
  const minutes = modified_time / 60;
  // 75 minutes -> '1 óra 15 perc'
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  if (hours > 0) {
    return `${hours} óra ${mins} perc`;
  }
  return `${mins} perc`
};

export const translateSportType = (sportType: SportType): string => {
  switch (sportType) {
    case SportType.AlpineSki:
      return "Alpesi sí";
    case SportType.BackcountrySki:
      return "Túrasí";
    case SportType.Badminton:
      return "Tollaslabda";
    case SportType.Canoeing:
      return "Kenuzás";
    case SportType.Crossfit:
      return "Crossfit";
    case SportType.EBikeRide:
      return "E-bike túra";
    case SportType.Elliptical:
      return "Elliptikus tréner";
    case SportType.EMountainBikeRide:
      return "E-mountain bike túra";
    case SportType.Golf:
      return "Golf";
    case SportType.GravelRide:
      return "Gravel kerékpározás";
    case SportType.Handcycle:
      return "Kézi kerékpározás";
    case SportType.HighIntensityIntervalTraining:
      return "Magas intenzitású intervallum edzés";
    case SportType.Hike:
      return "Túrázás";
    case SportType.IceSkate:
      return "Korcsolyázás";
    case SportType.InlineSkate:
      return "Görkorcsolyázás";
    case SportType.Kayaking:
      return "Kajakozás";
    case SportType.Kitesurf:
      return "Kitesurf";
    case SportType.MountainBikeRide:
      return "Hegyi kerékpározás";
    case SportType.NordicSki:
      return "Sífutás";
    case SportType.Pickleball:
      return "Pickleball";
    case SportType.Pilates:
      return "Pilates";
    case SportType.Racquetball:
      return "Racquetball";
    case SportType.Ride:
      return "Kerékpározás";
    case SportType.RockClimbing:
      return "Sziklamászás";
    case SportType.RollerSki:
      return "Görsí";
    case SportType.Rowing:
      return "Evezés";
    case SportType.Run:
      return "Futás";
    case SportType.Sail:
      return "Vitorlázás";
    case SportType.Skateboard:
      return "Gördeszkázás";
    case SportType.Snowboard:
      return "Snowboard";
    case SportType.Snowshoe:
      return "Hótalp";
    case SportType.Soccer:
      return "Labdarúgás";
    case SportType.Squash:
      return "Squash";
    case SportType.StairStepper:
      return "Lépcsőzőgép";
    case SportType.StandUpPaddling:
      return "Stand-up paddleboard";
    case SportType.Surfing:
      return "Szörfözés";
    case SportType.Swim:
      return "Úszás";
    case SportType.TableTennis:
      return "Asztalitenisz";
    case SportType.Tennis:
      return "Tenisz";
    case SportType.TrailRun:
      return "Terepfutás";
    case SportType.Velomobile:
      return "Velomobil";
    case SportType.VirtualRide:
      return "Virtuális kerékpározás";
    case SportType.VirtualRow:
      return "Virtuális evezés";
    case SportType.VirtualRun:
      return "Virtuális futás";
    case SportType.Walk:
      return "Gyaloglás";
    case SportType.WeightTraining:
      return "Súlyzós edzés";
    case SportType.Wheelchair:
      return "Kerekesszék";
    case SportType.Windsurf:
      return "Széllovaglás";
    case SportType.Workout:
      return "Edzés";
    case SportType.Yoga:
      return "Jóga";
    default:
      return sportType; // Return the original if no translation is available
  }
};

type PersonalStatsProps = {
  week: number
  queryClient: QueryClient
}

const PersonalStats = (props: PersonalStatsProps) => {
  const { queryClient } = props
  const client = new WeeklyTrainingStatsClient()
  const { week } = props
  const {user, loading: usersLoading, error: usersError} = useUser()
  const { isLoading, error, data } = useQuery<GetListActivitiesForAthleteResponse>({
    queryKey: ["list-activities-for-athlete", week],
    queryFn: () => {
      return client.activitiesForAthleteForPeriod(week, user!.stravaId);
    },
    enabled: !usersLoading
  });

  const isExcluded = (excludedContent: string): boolean => {
    return excludedContent === "1"
  }

  const handleMarkAsExcluded = async (activityId: number) => {
    await client.markActivityAsExcludedFromChallenges(activityId)
    await queryClient.invalidateQueries(["weekly-activity-reports", week])
    await queryClient.invalidateQueries(["list-activities-for-athlete"])
  }

  const handleMarkIncluded = async (activityId: number) => {
    await client.markActivityAsIncludedInChallenges(activityId)
    await queryClient.invalidateQueries(["weekly-activity-reports", week])
    await queryClient.invalidateQueries(["list-activities-for-athlete"])
  }

  if (isLoading || usersLoading) {
    return <Loader my={20} />;
  }

  if (error || usersError) {
    console.error(error);
    return <div>Nem sikerült lekérdezni a játékosokat.</div>;
  }

  if (!data || !data.data) {
    return <div>Nincs adat.</div>;
  }

  const isAtLeastXMinutesAgo = (date: Date, x: number): boolean => {
    const now = new Date();
    const xMinutesAgo = new Date(now.getTime() - x * 60 * 1000);
    return date <= xMinutesAgo;
  }

  const handleForceRefresh = async (stravaId: number) => {
    await client.forceActivityRefetch(stravaId)
    await queryClient.invalidateQueries(["weekly-activity-reports", week])
    await queryClient.invalidateQueries(["list-activities-for-athlete"])
  }

  const twoHoursAgo = (d: Date) => {
    // Create a new Date object to avoid modifying the original
    const newDate = new Date(d);

    // Subtract 2 hours (2 * 60 * 60 * 1000 milliseconds)
    newDate.setTime(newDate.getTime() - 2 * 60 * 60 * 1000);

    return newDate;
  }

  return (
    <>
      <Table striped withBorder withColumnBorders>
        <thead>
          <tr><th colSpan={9}><Text align="center" size={"lg"} fw={900}>Saját tevékenységek</Text></th></tr>
          <tr>
            <th>
              Név
            </th>
            <th>
              Típus
            </th>
            <th>
              Kezdés időpontja
            </th>
            <th>
              Időtartam
            </th>
            <th>
              Utolsó frissítés
            </th>
            <th>
              <Text align="center">Része a kihívásnak?</Text>
            </th>
            <th colSpan={2}>
              <Text align="center">Lehetőségek</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.data.sort((a, b) => {
            return new Date(a.start_date) < new Date(b.start_date) ? 1 : -1
          }).map(row => {
            return (
              <tr key={row.id}>
                <td>
                  <a href={`https://www.strava.com/activities/${row.strava_id}`} target="_blank">{row.name}</a>
                </td>
                <td>
                  {translateSportType(row.sport_type)}
                </td>
                <td>
                  {twoHoursAgo(row.start_date).toLocaleString("hu")}
                </td>
                <td>
                  {convertModifiedTime(row.moving_time)}
                </td>
                <td>
                  {twoHoursAgo(row.last_fetched_at).toLocaleString("hu")}
                </td>
                <td>
                  {isExcluded(row.excluded_from_challenges) ? (
                    <Button
                      color="red"
                      onClick={async () => {
                        await handleMarkIncluded(row.strava_id)
                      }}
                    >Nem</Button>
                  ) :
                    (<Button
                      color="green"
                      onClick={async () => {
                        await handleMarkAsExcluded(row.strava_id)
                      }}
                    >Igen</Button>
                    )
                  }
                </td>
                <td>
                  <Button
                    disabled={!isAtLeastXMinutesAgo(new Date(row.last_fetched_at), 15)}
                    onClick={async () => {
                      handleForceRefresh(row.strava_id)
                    }}
                  >Frissítés straváról</Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export const WeeklyTrainingStats = () => {
  const [urlParams, setUrlParams] = useSearchParams();
  const queryClient = useQueryClient();
  const week = urlParams.get("week");
  const weekN = week ? parseInt(week) : 1;
  const weeklyActivityReportsClient = new WeeklyTrainingStatsClient();
  const { isLoading, error, data } = useQuery<WeeklyActivityReportsResponse>({
    queryKey: ["weekly-activity-reports", weekN],
    queryFn: () => {
      return weeklyActivityReportsClient.weeklyActivityReports(weekN);
    },
  });

  const columns = useMemo<
    Column<{
      athlete_strava_id: number;
      athlete_name: string;
      modified_time: string;
      uwh_trainings: number;
      points: number;
    }>[]
  >(
    () => [
      {
        Header: "Strava azonosító",
        accessor: "athlete_strava_id",
      },
      {
        Header: "Név",
        accessor: "athlete_name",
      },
      {
        Header: "Korrigált idő",
        accessor: "modified_time",
      },
      {
        Header: "Hokiedzések száma",
        accessor: "uwh_trainings",
      },
      {
        Header: "Pontok",
        accessor: "points",
      },
    ],
    []
  );


  const prevWeek = () => {
    return weekN - 1;
  };

  const nextWeek = () => {
    return weekN + 1;
  };

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.reports ? data.reports.map((report) => {
      return {
        athlete_strava_id: report.athlete_strava_id,
        athlete_name: report.athlete_name,
        modified_time: convertModifiedTime(report.report.modified_time),
        uwh_trainings: report.report.uwh_trainings,
        points: report.points,
      };
    }) : [];
  }, [data]);

  const tableInstance = useTable({ columns, data: tableData }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (isLoading) {
    return <Loader my={20} />;
  }

  if (error) {
    console.error(error);
    return <div>Nem sikerült lekérdezni a játékosokat.</div>;
  }

  if (!data) {
    return <div>Nincs adat.</div>;
  }

  return (
    <>
      <Container>
        <h2>{weekN}. heti edzésstatisztika</h2>
        <br />
        <Text align="left">
          <b>Korrigált edzésidő:</b> a különböző tevékenységek súlyozva vannak:
          a többnyire alacsony intenzitású tevékenységek (séta, túra, stb.)
          valamivel kevesebb pontot érnek, mint a magas intenzitásúak (futás,
          úszás, stb.). A szorzó értéke 0.8 sétára és jógára, 0.9 túrára. Egyelőre minden
          más 1.0-ás súlyt kap.
        </Text>
        <br />
        <Text align="left">
          <Text fw={700}>Pontozás:</Text>
          <ul>
            <li>
              <Text>
                a héten legtöbbet sportoló 3 ember kap 5 - 4 - 3 pontot,
              </Text>
            </li>
            <li>
              <Text>
                aki nem végzett az első 3-ban, de legalább 8 órát sportolt, az
                kap 2 pontot,
              </Text>
            </li>
            <li>
              <Text>az első 2 hokiedzés után 2 pont jár, minden további hoki 1 pontot ér.</Text>
            </li>
          </ul>
        </Text>
        <br />
        <OrderableTable
          getTableBodyProps={getTableBodyProps}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        />
        <Flex>
          {prevWeek() > 0 ? (
            <Button
              onClick={async () => {
                setUrlParams((prev) => {
                  prev.set("week", prevWeek().toString());
                  return prev;
                })
                await queryClient.invalidateQueries(["weekly-activity-reports", weekN])
                await queryClient.invalidateQueries(["list-activities-for-athlete"])
              }
              }
              style={{
                marginTop: "1rem",
                marginRight: "auto",
                width: 250,
              }}
              leftIcon={<RiArrowLeftDoubleLine />}
            >
              {"Előző hét"}
            </Button>
          ) : null}
          {nextWeek() > 0 ? (
            <Button
              onClick={async () => {
                setUrlParams((prev) => {
                  prev.set("week", nextWeek().toString());
                  return prev;
                })
                await queryClient.invalidateQueries(["weekly-activity-reports", weekN])
                await queryClient.invalidateQueries(["list-activities-for-athlete"])
              }}
              style={{
                marginTop: "1rem",
                marginRight: 0,
                width: 250,
              }}
              rightIcon={<RiArrowRightDoubleLine />}
            >
              {"Következő hét"}
            </Button>
          ) : null}
        </Flex>
      </Container>
      <br />
      <PersonalStats week={weekN} queryClient={queryClient} />
      <br />
      <Text size={"xs"} fs={"italic"}>
        A táblázat nagyjából negyedóránként frissül, emiatt lehet, hogy a legújabb
        tevékenységek még nem jelennek meg rögtön.
      </Text>
    </>
  );
};
