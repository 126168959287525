import ky from "ky";
import { KyInstance } from "ky/distribution/types/ky";
import { API_URL, NEW_API_URL } from "./Config";
import { AddRoleToUserRequest, AllUsersResponse, GenericMessage, GetRolesResponse, GetUserResponse, LoginRequest, LoginResponse, UpdateUserRequest, User, WhoAmIResponse } from "./Descriptors";
import { getToken, setToken } from "./Login";

export class UsersClient {
  api: KyInstance;

  constructor() {
    this.api = ky.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            if (getToken()) {
              request.headers.set("Authorization", `Bearer ${getToken()}`);
            }
          },
        ],
      },
    });
  }

  getWhoAmI = async (): Promise<WhoAmIResponse> => {
    return await this.api.get(`${NEW_API_URL}/users/whoami`).json();
  };

  getUser = async (userId: number): Promise<GetUserResponse> => {
    return await this.api.get(`${NEW_API_URL}/users/${userId}`).json()
  }

  getAllUsers = async (): Promise<AllUsersResponse> => {
    return await this.api.get(`${NEW_API_URL}/users`).json()
  }

  update = async (userId: number, payload: UpdateUserRequest): Promise<GenericMessage> => {
    return (await this.api
      .put(`${NEW_API_URL}/users/${userId}`, { json: payload })
      .json());
  }

  addRole = async (payload: AddRoleToUserRequest): Promise<GenericMessage> => {
    return (await this.api
      .post(`${NEW_API_URL}/users/add-role`, { json: payload })
      .json());
  }

  getRoles = async (): Promise<GetRolesResponse> => {
    return await this.api.get(`${NEW_API_URL}/users/roles`).json()
  }

  login = async (payload: LoginRequest): Promise<LoginResponse> => {
    const data: LoginResponse = await this.api
      .post(`${API_URL}/users/login/`, { json: payload })
      .json()
    setToken(data.token);
    return data;
  }
}

