import { Text } from "@mantine/core"

export const AntonText = (text: string) => {
  return (
    <Text
      style={{
        fontFamily: "Anton",
        letterSpacing: "0.03rem",
      }}
    >
      {text}
    </Text>
  );
};
