import React from "react";
import { Loader, Table } from "@mantine/core";
import { getPositionByValue } from "../../util/position";
import { usePlayers } from "../../hooks/usePlayers";

type PlayerInfoTableProps = {
  playerId: string
}

const convertStatus = (status: string | undefined) => {
  if (status === undefined || status.toLowerCase() === "inactive" || status.toLowerCase() === "inaktív") {
    return "Inaktív";
  }
  return "Aktív";
}

export const PlayerInfoTable: React.FC<PlayerInfoTableProps> = (props: PlayerInfoTableProps) => {
  const { playerId } = props
  const { usePlayer, useAllPlayers, usePairing } = usePlayers()

  const { isLoading: isLoadingPlayer, error: errorPlayer, data: player } = usePlayer(playerId);
  const { isLoading: isLoadingPairing, error: errorPairing, data: pairing } = usePairing(playerId)
  const { isLoading: isLoadingPlayers, error: errorPlayers, data: players } = useAllPlayers()

  if (isLoadingPlayer || isLoadingPairing || isLoadingPlayers) {
    return <Loader />;
  }

  if (errorPlayer || errorPairing || errorPlayers) {
    console.error(errorPlayer);
    return <div>Nem sikerült lekérdezni a <b>{props.playerId}</b> számú játékost. Biztosan jó ID-t akarsz lekérdezni?</div>;
  }

  const getPlayersByIds = (ids: number[] | undefined) => {
    if (!ids) {
      return [];
    }
    const playerNames = ids.map(id => {
      const player = players?.find(p => p.id === Number(id));
      if (player) {
        return player.lname + " " + player.fname;
      }
      return `Ismeretlen (${id})`;
    });
    return playerNames;
  }

  return (
    <>
      <Table striped withBorder withColumnBorders>
        <tbody>
          <tr>
            <th>Név</th>
            <td>{player?.lname} {player?.fname}</td>
          </tr>
          <tr>
            <th>Erősség</th>
            <td>{player?.training_strength}</td>
          </tr>
          <tr>
            <th>Pozíció 1.</th>
            <td>{getPositionByValue(player?.primary_position)?.label}</td>
          </tr>
          <tr>
            <th>Pozíció 2.</th>
            <td>{getPositionByValue(player?.secondary_position)?.label}</td>
          </tr>
          <tr>
            <th>Státusz</th>
            <td>{convertStatus(player?.status)}</td>
          </tr>
          <tr>
            <th>Leggyakoribb csapattárs</th>
            {
              pairing?.most_paired
                ? <td>{pairing?.most_paired.value} alkalommal: {getPlayersByIds(pairing?.most_paired.player_ids).join(", ")} </td>
                : <td>Nincs adat</td>
            }
          </tr>
          <tr>
            <th>Legritkább csapattárs</th>
            {
              pairing?.least_paired
                ? <td>{pairing?.least_paired.value} alkalommal: {getPlayersByIds(pairing?.least_paired.player_ids).join(", ")}</td>
                : <td>Nincs adat</td>
            }
          </tr>
        </tbody>
      </Table>
    </>
  )
}
