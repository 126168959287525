import { useQuery } from "react-query";
import { GetHockeyEventsResponse } from "../api/Descriptors";
import { HockeyEventsClient } from "../api/HockeyEvent";
import { Loader, Paper, PaperProps, Text } from "@mantine/core";
import { formatDate } from "../util/naming";
import { useNavigate } from "react-router";
import { useHover } from "@mantine/hooks";

type HockeyEventCardProps = {
  backgroundColor?: PaperProps["bg"];
} & GetHockeyEventsResponse["hockey_events"][0];

const HockeyEventCard = (props: HockeyEventCardProps) => {
  const { hovered, ref } = useHover();
  const navigate = useNavigate();

  const navigateToDetailedView = (id: number) => {
    navigate(`/events/${id}`);
  };

  return (
    <Paper
      ref={ref}
      shadow="xl"
      mt={"1rem"}
      pt={"1rem"}
      key={props.id}
      onClick={() => navigateToDetailedView(props.id)}
      style={
        hovered
          ? { cursor: "pointer", backgroundColor: "#f0f0f0" }
          : {
            cursor: "pointer",
          }
      }
      bg={props.backgroundColor}
    >
      <Text fz={"lg"}>{formatDate(props.date)}</Text>
      <Text fz={"xl"} tt={"capitalize"} td={"underline"}>
        {props.title}
      </Text>
      <Text fz={"sm"}>{props.type}</Text>
      <Text fz={"md"}>{props.description}</Text>
      <br />
    </Paper>
  );
};

export const HockeyEventList: React.FC = () => {
  const hockeyEventsClient = new HockeyEventsClient();
  const { isLoading, error, data } = useQuery<GetHockeyEventsResponse>({
    queryKey: "hockey-events",
    queryFn: () => {
      const data = hockeyEventsClient.getAll();

      return data;
    },
    suspense: true,
  });

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {data?.hockey_events.map((hockeyEvent) => {
        //only display if the event is in the future
        if (new Date(hockeyEvent.date) < new Date()) {
          return null;
        }

        return <HockeyEventCard key={hockeyEvent.id} {...hockeyEvent} />;
      })}
      <hr />
      {data?.hockey_events.map((hockeyEvent) => {
        //only display if the event is in the future
        if (new Date(hockeyEvent.date) >= new Date()) {
          return null;
        }

        return (
          <HockeyEventCard
            key={hockeyEvent.id}
            {...hockeyEvent}
            backgroundColor={"gray"}
          />
        );
      })}
    </div>
  );
};
