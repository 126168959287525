import { useEffect, useState } from "react";
import {
  Stepper,
  Button,
  Group,
  TextInput,
  Table,
  Textarea,
  NativeSelect,
  Loader,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DatePicker } from "@mantine/dates";
import { Container } from "react-bootstrap";
import { UpdateHockeyEventRequest } from "../api/Descriptors";
import { useNavigate, useParams } from "react-router";
import { HockeyEventsClient } from "../api/HockeyEvent";
import { formatDate } from "../util/naming";
import { useQuery, useQueryClient } from "react-query";

export const HockeyEventEditor = () => {
  const queryClient = useQueryClient();
  const hockeyEventClient = new HockeyEventsClient();
  const { eventId } = useParams<{ eventId: string }>();
  const { isLoading, error, data } = useQuery(
    `hockey-event-${eventId}`,
    async () => {
      if (!eventId) {
        return Promise.reject("Event id is not defined");
      }
      return await hockeyEventClient.get(eventId);
    }
  );

  const [active, setActive] = useState(0);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      type: data?.data.type || "",
      description: data?.data.description || "",
      title: data?.data.title || "",
      date: data?.data.date ? new Date(data.data.date) : new Date(),
    },

    validate: (values) => {
      if (active === 1) {
        return {
          title: !values.title ? "A cím megadása kötelező" : null,
        };
      }

      return {};
    },
  });

  useEffect(() => {
    if (data) {
      form.setValues({
        type: data.data.type || "",
        description: data.data.description || "",
        title: data.data.title || "",
        date: data.data.date ? new Date(data.data.date) : new Date(),
      });
    }
  }, [data, form]);

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 2 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const updateHockeyEvent = async () => {
    const updateHockeyEventRequest: UpdateHockeyEventRequest = {
      title: form.values.title,
      description: form.values.description,
      type: form.values.type,
      date: form.values.date,
    };

    const resp = await new HockeyEventsClient().update(
      eventId!,
      updateHockeyEventRequest
    );
    if (resp.status === "success") {
      queryClient.invalidateQueries(`hockey-event-${eventId}`);
      queryClient.invalidateQueries(`hockey-events`);
      navigate(`/events`);
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    console.log(error);
    return <div>{`Nem sikerült betölteni az oldalt`}</div>;
  }

  return (
    <>
      <Container>
        <Stepper active={active} breakpoint="sm">
          <Stepper.Step label="Cím" description="Személyi adatok">
            <TextInput label="Cím" {...form.getInputProps("title")} />
            <Textarea
              label="Leírás"
              placeholder=""
              {...form.getInputProps("description")}
            />
            <NativeSelect
              data={["Szociális", "Verseny", "Edzés"]}
              label="Típus"
              description="Válassz egy típust"
              {...form.getInputProps("type")}
            />
          </Stepper.Step>

          <Stepper.Step label="Időpont" description="Az esemény időpontja">
            <DatePicker
              label="Dátum"
              placeholder="Válassz egy dátumot"
              description="Az esemény időpontja"
              {...form.getInputProps("date")}
            />
          </Stepper.Step>

          <Stepper.Completed>
            <Container>
              <Table striped withBorder withColumnBorders>
                <tbody>
                  <tr>
                    <th>Cím</th>
                    <td>{form.values.title}</td>
                  </tr>
                  <tr>
                    <th>Típus</th>
                    <td> {form.values.type} </td>
                  </tr>
                  <tr>
                    <th>Leírás</th>
                    <td> {form.values.description} </td>
                  </tr>
                  <tr>
                    <th>Dátum</th>
                    <td> {formatDate(form.values.date)} </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Stepper.Completed>
        </Stepper>

        <Group position="right" mt="xl">
          {active !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Vissza
            </Button>
          )}
          {active !== 2 && <Button onClick={nextStep}>Következő</Button>}
          {active === 2 && <Button onClick={updateHockeyEvent}>Mentés</Button>}
        </Group>
      </Container>
    </>
  );
};
