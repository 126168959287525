/*
  /api/v1/trainings
*/
export interface TrainingData {
  black_team: number[];
  white_team: number[];
  black_score: number;
  white_score: number;
  black_scorers: any;
  white_scorers: any;
  players: number[];
  id: number;
  date: string;
  swimming_players: number[];
}

/*
  POST /api/v1/team-picker response
*/

export interface TeamPickerResponseTeam {
  members: number[];
  strength: number;
}

export interface TeamPickerResponse {
  message: string;
  status: string;
  teams: {
    team_a: TeamPickerResponseTeam;
    team_b: TeamPickerResponseTeam;
  }[];
}

/*
  /api/v1/trainings/new
*/
export interface NewTrainingDataRequest {
  black_team: number[];
  white_team: number[];
  swimming_players: number[];
}

export interface NewTrainingDataResponse {
  message: string;
  status: string;
  id: number;
}

/*
  GET /api/v1/players/
*/
export interface PlayerInfo {
  active: boolean;
  club_id: number;
  description: string;
  fname: string;
  lname: string;
  id: number;
  primary_position: number;
  secondary_position: number;
  training_strength: number;
  status: string;
}

export interface PairingInfo {
  least_paired: {
    player_ids: number[];
    value: number;
  };
  most_paired: {
    player_ids: number[];
    value: number;
  };
}

export interface NewPlayerInput {
  fname: string;
  lname: string;
  primary_position: number;
  secondary_position: number;
  training_strength: number;
  status: string;
}

export interface NewPlayerResponse {
  message: string;
  status: string;
  player_id: number;
}

/*
  GET /api/v1/players/id-name-map
*/
export interface PlayerIdNameMap {
  [id: string]: { fname: string; lname: string };
}

/*
  PUT /api/v1/goal-scorers
*/
export interface ScorerDataUpdate {
  training_id: string;
  scorers: {
    [id: number]: number;
  };
}

export interface ScorerEntry {
  player_id: number;
  scored: number;
  training_id: number;
}

export interface GenericMessage {
  message: string;
  status: string;
}

/*
  GET /ap1/v1/strength-values/latest-for-players?ids=...&date=...
*/
export interface LatestStrengthValuesForPlayersResponse {
  [id: string]: number;
}

/*
  PUT /ap1/v1/strength-values/
*/
export interface UpsertStrengthValuesRequest {
  date: string;
  new_values: {
    player_id: number;
    strength_value: number;
  }[];
}

/*
  POST /api/v1/clips/multi
*/

export interface NewClipRequestElement {
  video_url: string;
  start_ts: string;
  end_ts: string;
  tags: string[];
}
export interface NewClipsRequest {
  clips: NewClipRequestElement[];
}

export interface WinningPercentageInfo {
  [id: number]: {
    all: number;
    won: number;
    draw: number;
  };
}

export interface CreatePushSubscription {
  endpoint: string;
  keys: {
    auth: string;
    p256dh: string;
  };
}

/*
  GET /api/v1/hockey-events/id
*/
export interface GetHockeyEventResponse {
  data: {
    id: number;
    date: string;
    title: string;
    type: string;
    description: string;
  };
}

export interface GetHockeyEventsResponse {
  hockey_events: {
    id: number;
    date: Date;
    title: string;
    type: string;
    description: string;
  }[];
}

export interface CreateHockeyEventRequest {
  date: Date;
  title: string;
  type: string;
  description: string;
}

export interface UpdateHockeyEventRequest {
  date: Date;
  title: string;
  type: string;
  description: string;
}

export interface GetParticipantCount {
  count: number;
  message: string;
  status: string;
}

export interface WhoAmIResponse {
  id: number;
  email?: string;
  username: string;
  firstname?: string;
  lastname?: string;
  stravaId: number;
  roles: string[];
}

export interface WeeklyActivityReport {
  modified_time: number;
  uwh_trainings: number;
}

export interface WeeklyActivityReports {
  report: WeeklyActivityReport;
  athlete_name: string;
  athlete_strava_id: number;
  points: number;
  distance_swam: number;
}

export interface WeeklyActivityReportsResponse {
  reports: WeeklyActivityReports[];
  calculation_description: string;
}

export interface ChallengePeriodsResponse {
  challenge_periods: {
    first_week: number;
    last_week: number;
    id: number;
  }[];
}

export interface CreateChallengePeriodRequest {
  first_week: number;
  last_week: number;
}

export interface CreateChallengePeriodResponse {
  data: {
    first_week: number;
    last_week: number;
    id: number;
  };
  message: string;
}

export interface ChallengePointsForPeriodResponse {
  data: {
    athlete_name: string;
    athlete_strava_id: number;
    points: number;
    distance_swam: number;
  }[];
}

export const SportType = {
  AlpineSki: "AlpineSki",
  BackcountrySki: "BackcountrySki",
  Badminton: "Badminton",
  Canoeing: "Canoeing",
  Crossfit: "Crossfit",
  EBikeRide: "EBikeRide",
  Elliptical: "Elliptical",
  EMountainBikeRide: "EMountainBikeRide",
  Golf: "Golf",
  GravelRide: "GravelRide",
  Handcycle: "Handcycle",
  HighIntensityIntervalTraining: "HighIntensityIntervalTraining",
  Hike: "Hike",
  IceSkate: "IceSkate",
  InlineSkate: "InlineSkate",
  Kayaking: "Kayaking",
  Kitesurf: "Kitesurf",
  MountainBikeRide: "MountainBikeRide",
  NordicSki: "NordicSki",
  Pickleball: "Pickleball",
  Pilates: "Pilates",
  Racquetball: "Racquetball",
  Ride: "Ride",
  RockClimbing: "RockClimbing",
  RollerSki: "RollerSki",
  Rowing: "Rowing",
  Run: "Run",
  Sail: "Sail",
  Skateboard: "Skateboard",
  Snowboard: "Snowboard",
  Snowshoe: "Snowshoe",
  Soccer: "Soccer",
  Squash: "Squash",
  StairStepper: "StairStepper",
  StandUpPaddling: "StandUpPaddling",
  Surfing: "Surfing",
  Swim: "Swim",
  TableTennis: "TableTennis",
  Tennis: "Tennis",
  TrailRun: "TrailRun",
  Velomobile: "Velomobile",
  VirtualRide: "VirtualRide",
  VirtualRow: "VirtualRow",
  VirtualRun: "VirtualRun",
  Walk: "Walk",
  WeightTraining: "WeightTraining",
  Wheelchair: "Wheelchair",
  Windsurf: "Windsurf",
  Workout: "Workout",
  Yoga: "Yoga",
} as const;
export type SportType = (typeof SportType)[keyof typeof SportType];

export interface StravaActivity {
  id: number;
  strava_id: number;
  sport_type: SportType;
  moving_time: number;
  strava_upload_id: number;
  start_date: Date;
  name: string;
  description: string;
  strava_athlete_id: number;
  last_fetched_at: Date;
  excluded_from_challenges: string;
  distance: number;
}

export interface GetListActivitiesForAthleteResponse {
  data: StravaActivity[];
  message: string;
}

export interface MarkActivityAsExcludedFromChallengeResquest {
  strava_activity_id: number;
  challenge_id: number;
}

export interface ActivityRefetchRequest {
  strava_activity_id: number;
}

export interface User {
  id: number;
  username?: string;
  player_id?: number;
  club_id?: number;
  strava_id?: number;
  strava_first_name?: string;
  strava_last_name?: string;
  player_first_name?: string;
  player_last_name?: string;
}

export interface AllUsersResponse {
  data: User[];
  message: string;
}

export interface GetUserResponse {
  data: User;
  message: string;
}

export interface UpdateUserRequest {
  club_id?: number;
  player_id?: number;
}

export interface Club {
  id: number;
  name: string;
  players: number[];
}

export interface GetClubsResponse {
  data: Club[];
  message: string;
}

export interface GetRolesResponse {
  data: string[];
  message: string;
}

export interface AddRoleToUserRequest {
  user_id: number;
  role: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  message: string;
  status: string;
  token: string;
}
