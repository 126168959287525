import { useState } from "react";
import {
  Stepper,
  Button,
  Group,
  TextInput,
  Table,
  Textarea,
  NativeSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DatePicker } from "@mantine/dates";
import { Container } from "react-bootstrap";
import { CreateHockeyEventRequest } from "../api/Descriptors";
import { useNavigate } from "react-router";
import { HockeyEventsClient } from "../api/HockeyEvent";
import { formatDate } from "../util/naming";
import { useQueryClient } from "react-query";

export const HockeyEventCreator = () => {
  const queryClient = useQueryClient();
  const [active, setActive] = useState(0);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      type: "",
      description: "",
      title: "",
      date: new Date(),
    },

    validate: (values) => {
      if (active === 1) {
        return {
          title: !values.title ? "A cím megadása kötelező" : null,
        };
      }

      return {};
    },
  });

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 2 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const createHockeyEvent = async () => {
    const createHockeyEventRequest: CreateHockeyEventRequest = {
      title: form.values.title,
      description: form.values.description,
      type: form.values.type,
      date: form.values.date,
    };

    try {
      const resp = await new HockeyEventsClient().createNew(
        createHockeyEventRequest
      );
      queryClient.invalidateQueries("hockey-events");
      navigate(`/events/${resp.data.id}`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Container>
        <Stepper active={active} breakpoint="sm">
          <Stepper.Step label="Cím" description="Személyi adatok">
            <TextInput label="Cím" {...form.getInputProps("title")} />
            <Textarea
              label="Leírás"
              placeholder=""
              {...form.getInputProps("description")}
            />
            <NativeSelect
              data={["Szociális", "Verseny", "Edzés"]}
              label="Típus"
              description="Válassz egy típust"
              {...form.getInputProps("type")}
            />
          </Stepper.Step>

          <Stepper.Step label="Időpont" description="Az esemény időpontja">
            <DatePicker
              label="Dátum"
              placeholder="Válassz egy dátumot"
              description="Az esemény időpontja"
              locale="hu"
              {...(form.getInputProps("date"),
              {
                onChange: (value) => {
                  // to avoid timezone issues
                  value?.setHours(12, 0, 0, 0);
                  form.setFieldValue("date", value!);
                },
              })}
            />
          </Stepper.Step>

          <Stepper.Completed>
            <Container>
              <Table striped withBorder withColumnBorders>
                <tbody>
                  <tr>
                    <th>Cím</th>
                    <td>{form.values.title}</td>
                  </tr>
                  <tr>
                    <th>Típus</th>
                    <td> {form.values.type} </td>
                  </tr>
                  <tr>
                    <th>Leírás</th>
                    <td> {form.values.description} </td>
                  </tr>
                  <tr>
                    <th>Dátum</th>
                    <td> {formatDate(form.values.date)} </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Stepper.Completed>
        </Stepper>

        <Group position="right" mt="xl">
          {active !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Vissza
            </Button>
          )}
          {active !== 2 && <Button onClick={nextStep}>Következő</Button>}
          {active === 2 && <Button onClick={createHockeyEvent}>Mentés</Button>}
        </Group>
      </Container>
    </>
  );
};
