import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Home } from "./routes/Home";
import { AllTrainings } from "./routes/AllTrainings";
import { AllTrainingsTable } from "./components/training/training-all-table";
import { SingleTrainingPlayerInfoTable } from "./components/SingleTrainingPlayerInfoTable";
import { NotFound } from "./routes/NotFound";
import { History } from "./routes/History";
import { Rules } from "./routes/Rules";
import { Teams } from "./routes/Teams";
import { ScoreInput } from "./routes/ScoreInput";
import { NewTraining } from "./routes/NewTraining";
import { StrengthValueUpdaterForTraining } from "./routes/StrengthValueUpdaterForTraining";
import { GoalStats } from "./routes/GoalStats";
import { NewClips } from "./routes/NewClips";
import { TeamPicker } from "./routes/TeamPicker";
import { TrainingMaterialHorns } from "./components/TrainingMaterial_Horns";
import { LoginPage } from "./routes/LoginPage";
import { RequireLoginToken } from "./components/RequireLoginToken";
import { WinningPercentageView } from "./routes/winning-percentage";
import { QueryClient, QueryClientProvider } from "react-query";
import { NewPlayerView } from "./routes/new-player";
import { UpdatPlayer } from "./routes/players/update";
import { SinglePlayer } from "./routes/players/single-player";
import { AllPlayers } from "./routes/players/all-players";
import { AllPlayersTable } from "./components/player/table-all";
import { Loader } from "@mantine/core";
import { StatsLayout } from "./routes/stats-layout";
import { MaterialsLayout } from "./routes/materials-layout";
import { AboutLayout } from "./routes/about-layout";
import { MainNavbar } from "./components/Navbar";
import { HockeyEventEditor } from "./routes/hockey-event-editor";
import { MantineProvider } from "@mantine/core";
import { HockeyEventList } from "./components/hockey-event-list";
import { HockeyEventsLayout } from "./routes/hockey-events-layout";
import { HockeyEventDetails } from "./components/hockey-event-details";
import { HockeyEventCreator } from "./routes/hockey-event-creator";
import { ParticipantCount } from "./routes/participant-count";
import { WeeklyTrainingStats } from "./routes/weekly-training-stats";
import { MovingChallengeInfo } from "./routes/moving-challenge-info";
import { UsersList } from "./components/admin/users-list";
import { UserDetailed } from "./components/admin/single-user";
import { UserProvider } from "./context/UserContext";

type AppProps = {};

type MantineColorscheme = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
];

const myblue = [
  "#BED3EB",
  "#6B9ED9",
  "#2B76CD",
  "#1A5AA3",
  "#0E4482",
  "#063369",
  "#002754",
  "#031B37",
  "#041324",
  "#040D18",
] as MantineColorscheme;
const mygray = [
  "#D9DBE0",
  "#B0B4BF",
  "#8D94A5",
  "#6F7890",
  "#5A6172",
  "#494E5A",
  "#3B3E47",
  "#303239",
  "#27282D",
  "#1F2024",
] as MantineColorscheme;
const myyellow = [
  "#F6F6C3",
  "#F1F17E",
  "#F5F53D",
  "#FFFF00",
  "#C2C20A",
  "#949410",
  "#717112",
  "#565612",
  "#424211",
  "#333310",
] as MantineColorscheme;

export const App: React.FC<AppProps> = (props) => {
  return (
    <MantineProvider
      theme={{
        colors: {
          "own-blue": myblue,
          "own-gray": mygray,
          "own-yellow": myyellow,
        },
        fontFamily: "Anton, sans-serif",
      }}
    >
      <div className="App">
        <QueryClientProvider client={new QueryClient()}>
          <UserProvider>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<MainNavbar />}>
                  <Route path="" element={<Home />} />
                  <Route path="users">
                    <Route path="" element={<UsersList />} />
                    <Route path=":userId" element={<UserDetailed />} />
                  </Route>
                  <Route path="login" element={<LoginPage />} />
                  <Route path="events" element={<HockeyEventsLayout />}>
                    <Route path="" element={<HockeyEventList />} />
                    <Route path=":eventId" element={<HockeyEventDetails />} />
                    <Route
                      path="new"
                      element={
                        <RequireLoginToken>
                          <HockeyEventCreator />
                        </RequireLoginToken>
                      }
                    />
                    <Route
                      path=":eventId/edit"
                      element={
                        <RequireLoginToken>
                          <HockeyEventEditor />
                        </RequireLoginToken>
                      }
                    />
                  </Route>
                  <Route path="/players" element={<AllPlayers />}>
                    <Route path="" element={<AllPlayersTable />} />
                    <Route path=":playerId" element={<SinglePlayer />} />
                    <Route
                      path=":playerId/update"
                      element={
                        <RequireLoginToken>
                          <UpdatPlayer />
                        </RequireLoginToken>
                      }
                    />
                    <Route
                      path="new"
                      element={
                        <RequireLoginToken>
                          <NewPlayerView />
                        </RequireLoginToken>
                      }
                    />
                  </Route>

                  <Route path="trainings" element={<AllTrainings />}>
                    <Route path="" element={<AllTrainingsTable />} />
                    <Route
                      path=":trainingId"
                      element={<SingleTrainingPlayerInfoTable />}
                    />
                    <Route
                      path=":trainingId/score"
                      element={
                        <RequireLoginToken>
                          <ScoreInput />
                        </RequireLoginToken>
                      }
                    />
                    <Route
                      path=":trainingId/strength-values"
                      element={
                        <RequireLoginToken>
                          <StrengthValueUpdaterForTraining />
                        </RequireLoginToken>
                      }
                    />
                    <Route
                      path="new"
                      element={
                        <RequireLoginToken>
                          <NewTraining />
                        </RequireLoginToken>
                      }
                    />
                    <Route
                      path="participant-count"
                      element={<ParticipantCount />}
                    />
                  </Route>
                  <Route path="team-picker" element={<TeamPicker />} />
                  <Route path="training-materials" element={<MaterialsLayout />}>
                    <Route path="horns" element={<TrainingMaterialHorns />} />
                  </Route>
                  <Route path="stats" element={<StatsLayout />}>
                    <Route path="goal" element={<GoalStats />} />
                    <Route
                      path="winning-percentage"
                      element={<WinningPercentageView />}
                    />
                  </Route>
                  <Route path="challenge" element={<StatsLayout />}>
                    <Route path="periods" element={<MovingChallengeInfo />} />
                    <Route path="weekly" element={<WeeklyTrainingStats />} />
                  </Route>
                  <Route path="about" element={<AboutLayout />}>
                    <Route path="history" element={<History />} />
                    <Route path="rules" element={<Rules />} />
                    <Route path="hungarian-teams" element={<Teams />} />
                  </Route>
                  <Route path="score" element={<ScoreInput />} />
                  <Route path="*" element={<NotFound />} />
                  <Route path="clips">
                    <Route path="new" element={<NewClips />} />
                  </Route>
                </Route>
              </Routes>
            </Suspense>
          </UserProvider>
        </QueryClientProvider>
      </div>
    </MantineProvider>
  );
};
