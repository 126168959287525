import React from "react";
import { FC } from "react"
import { Col, Row } from "react-bootstrap";
import { PlusMinusButton } from "./PlusMinusButton";

type GoalInputFieldProps = {
	score: number,
	incrementer: () => void,
	decrementer: () => void,
	name: string,
	color: string
}

export const GoalInputField: FC<GoalInputFieldProps> = (props) => {
	const incrementer = () => {
		props.incrementer()
	}

	const decrementer = () => {
		props.decrementer()
	}

	const getTextColor = (backgroundColor: string) => {
		switch (backgroundColor) {
			case "black":
				return "white"
			case "white":
				return "black"
			default:
				return "white"
		}
	}

	return (
		<Row>
			<Col style={{ backgroundColor: props.color, color: getTextColor(props.color) }}>{props.name}</Col>
			<Col style={{ backgroundColor: props.color, color: getTextColor(props.color) }}>{props.score}</Col>
			<Col style={{ backgroundColor: props.color, color: getTextColor(props.color) }}><PlusMinusButton doPlus={incrementer} doMinus={decrementer} /></Col>
		</Row >
	)
}