import {
  Group,
  Loader,
  Select,
  TableProps,
} from "@mantine/core";
import React, { useMemo } from "react";
import { Column, useSortBy, useTable } from "react-table";
import { convertStatus } from "../../util/naming";
import { getPositionByValue } from "../../util/position";
import { OrderableTable } from "../base/orderable-table";
import { useSearchParams } from "react-router-dom";
import { usePlayers } from "../../hooks/usePlayers";
import { useClubs } from "../../hooks/useClubs";

type AllPlayersProps = {} & TableProps;

export const AllPlayersTable: React.FC<AllPlayersProps> = (
  props: AllPlayersProps
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { useAllPlayers } = usePlayers()
  const { useAllClubs } = useClubs()

  const { isLoading: isLoadingP, error: errorP, data: players } = useAllPlayers();
  const { isLoading: isLoadingC, error: errorC, data: clubs } = useAllClubs();

  const columns = useMemo<
    Column<{
      id: number;
      name: string;
      primary_position: string;
      secondary_position: string;
      status: string;
    }>[]
  >(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Név",
        accessor: "name",
      },
      {
        Header: "Pozíció 1.",
        accessor: "primary_position",
      },
      {
        Header: "Pozíció 2.",
        accessor: "secondary_position",
      },
      {
        Header: "Státusz",
        accessor: "status",
      },
    ],
    []
  );
  const tableData = useMemo(() => {
    if (!players) {
      return [];
    }
    const statusQParam = searchParams.get("status");
    const clubQParam = searchParams.get("club");

    return players
      .filter((player) => {
        if (!statusQParam) return true;

        if (["aktív", "active", "aktiv"].includes(statusQParam.toLowerCase())) {
          return player.active;
        }

        if (
          ["inaktív", "inactive", "inaktiv"].includes(
            statusQParam.toLocaleLowerCase()
          )
        ) {
          return !player.active;
        }
      })
      .filter((player) => {
        if (!clubQParam) return true;
        const cid = parseInt(clubQParam);
        if (Number.isNaN(cid)) return true;

        return player.club_id === cid;
      })
      .map((player) => {
        return {
          id: player.id,
          name: player.lname + " " + player.fname,
          primary_position: getPositionByValue(player.primary_position).label,
          secondary_position: getPositionByValue(player.secondary_position)
            .label,
          status: convertStatus(player.status),
        };
      });
  }, [players, searchParams]);

  const tableInstance = useTable({ columns, data: tableData }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (isLoadingP || isLoadingC) {
    return <Loader my={20} />;
  }

  if (errorP || errorC) {
    return <div>{"Nem sikerült lekérdezni a játékosokat."}</div>;
  }

  const linkAccessor = {
    accessor: "id",
    baseUrl: "/players",
  };

  const searchTranslate = (s: string | null) => {
    if (s === null) return "Mind";
    if (s === "active") return "Aktív";
    if (s === "inactive") return "Inaktív";
    return "Mind";
  };

  return (
    <Group>
      <Select
        label={"Aktivitás szűrő"}
        data={["Aktív", "Inaktív", "Mind"]}
        value={searchTranslate(searchParams.get("status"))}
        onChange={(value) => {
          const currentParams = new URLSearchParams(searchParams);
          switch (value) {
            case "Aktív":
              currentParams.set("status", "active");
              break;
            case "Inaktív":
              currentParams.set("status", "inactive");
              break;
            default:
              currentParams.delete("status");
              break;
          }
          setSearchParams(currentParams);
        }}
      />
      <Select
        label={"Klub"}
        clearable
        data={
          clubs?.data.map((c) => {
            return {
              value: c.id.toString(),
              label: c.name,
            };
          }) ?? []
        }
        onChange={(value) => {
          const currentParams = new URLSearchParams(searchParams);
          if (value) {
            currentParams.set("club", value);
          } else {
            currentParams.delete("club");
          }
          setSearchParams(currentParams);
        }}
      />
      <OrderableTable
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        {...props}
        linkAccessor={linkAccessor}
      />
    </Group>
  );
};
