import { Table } from "@mantine/core";
import { Link } from "react-router-dom";
import { Row } from "react-table";

type OrderableTableProps = {
  getTableProps: () => any;
  headerGroups: any[];
  rows: Row<any>[];
  prepareRow: (row: any) => void;
  getTableBodyProps: () => any;
  linkAccessor?: {
    accessor: string;
    baseUrl: string;
  };
  caption?: string;
};

export const OrderableTable = (props: OrderableTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    props;

  return (
    <Table
      {...getTableProps()}
      striped
      highlightOnHover
      withBorder
      withColumnBorders
      captionSide="top"
    >
      {props.caption && <caption>{props.caption}</caption>}
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{ textAlign: "center" }}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>
                  {cell.column.id !== props.linkAccessor?.accessor ? (
                    cell.render("Cell")
                  ) : (
                    <Link to={`${props.linkAccessor?.baseUrl}/${cell.value}`}>
                      {cell.render("Cell")}
                    </Link>
                  )}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
