import React from "react";
import { FC, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { TagAPI } from "../api/Tag";

type NewTagModalProps = {
	show: boolean
	hide: () => void
	closeCallback: () => void
}

export const NewTagModal: FC<NewTagModalProps> = (props) => {
	const [newTag, setNewTag] = useState("")

	const handleClose = async () => {
		try {
			await new TagAPI().createOne({ name: newTag })
			setNewTag("")
			props.closeCallback()
			props.hide()
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<Modal show={props.show} onHide={() => props.hide()}>
			<Modal.Header closeButton>
				<Modal.Title>Új címke hozzáadása</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Label htmlFor="newTag">Új címke</Form.Label>
				<Form.Control id="newTag" value={newTag} onChange={(e) => setNewTag(e.target.value)} />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="success" onClick={handleClose}>
					Mentés
				</Button>
			</Modal.Footer>
		</Modal>
	)
}