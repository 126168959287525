import { useQuery } from "react-query";
import { GetHockeyEventResponse } from "../api/Descriptors";
import { HockeyEventsClient } from "../api/HockeyEvent";
import { Group, Loader, Modal, Paper, Text } from "@mantine/core";
import { formatDate } from "../util/naming";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useState } from "react";

type HockeyEventSingleProps = {
  eventId: string;
};

type DeleteHockeyEventModalProps = {
  eventId: String;
  opened: boolean;
  setOpened: (opened: boolean) => void;
};

const DeleteHockeyEventModal = (props: DeleteHockeyEventModalProps) => {
  const { eventId } = props;
  const navigate = useNavigate();
  const hockeyEventsClient = new HockeyEventsClient();

  const onDeleteHockeyEvent = async (id: String | string | undefined) => {
    if (!id) {
      throw new Error("No id");
    }
    console.log("HEEEEEEEERE")
    await hockeyEventsClient.delete(id.toString());
    console.log("AFTEEEEEER")
    navigate("/events");
  };

  return (
    <>
      <Modal
        opened={props.opened}
        onClose={() => {
          props.setOpened(false);
        }}
        title="Esemény törlése"
      >
        <Text>Biztosan törölni szeretnéd az eseményt?</Text>
        <Group position="right">
          <Button
            style={{ marginTop: "1rem" }}
            onClick={() => {
              props.setOpened(false);
            }}
          >
            Mégsem
          </Button>
          <Button
            style={{ marginTop: "1rem" }}
            variant="danger"
            onClick={() => {
              onDeleteHockeyEvent(eventId);
            }}
          >
            Törlés
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export const HockeyEventDetails: React.FC = () => {
  const { eventId } = useParams<HockeyEventSingleProps>();
  const hockeyEventsClient = new HockeyEventsClient();
  const [opened, setOpened] = useState(false);
  const {
    isLoading,
    error,
    data: eventData,
  } = useQuery<GetHockeyEventResponse>({
    queryKey: `hockey-events-${eventId}`,
    queryFn: () => {
      if (!eventId) {
        throw new Error("No eventId");
      }
      return hockeyEventsClient.get(eventId);
    },
    suspense: true,
  });

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <DeleteHockeyEventModal
        eventId={eventId!}
        opened={opened}
        setOpened={setOpened}
      />
      <Paper shadow="xl" mt={"1rem"} pt={"1rem"}>
        <div>{formatDate(eventData?.data.date)}</div>
        <div>{eventData?.data.title}</div>
        <div>{eventData?.data.type}</div>
        <div>{eventData?.data.description}</div>
        <br />
      </Paper>
      <Group position="center">
        <Button
          style={{
            marginTop: "1rem",
            marginLeft: "auto",
          }}
          variant="primary"
          href={`/events/${eventId}/edit`}
        >
          Szerkesztés
        </Button>
        <Button
          style={{
            marginTop: "1rem",
          }}
          variant="danger"
          onClick={() => {
            setOpened(true);
          }}
        >
          Törlés
        </Button>
      </Group>
    </>
  );
};
