import React from "react";
import { FC, useState } from "react";
import { Button } from "react-bootstrap";

type WhiteBlackBlueButtonProps = {
	style: any
	colorVariants: string[]
	name: string
	stateCallback: (state: number) => void
}

export const WhiteBlackBlueButton: FC<WhiteBlackBlueButtonProps> = (props) => {

	let [currentVariant, setCurrentVariant] = useState(0);

	const setNextVariant = () => {
		setCurrentVariant((currentVariant) => {
			const nextVariant = currentVariant < props.colorVariants.length - 1 ? currentVariant + 1 : 0
			props.stateCallback(nextVariant)
			return nextVariant
		})
	}
	return (
		<Button variant={props.colorVariants[currentVariant]} onClick={setNextVariant} style={props.style}>
			{props.name}
		</Button>
	)
}