import { useState } from "react";
import { TrainingClient } from "../api/Trainings";
import { DatePicker } from "@mantine/dates";
import { Button } from "@mantine/core";

export const ParticipantCount = () => {
  const trainingApi = new TrainingClient();
  const [participantCount, setParticipantCount] = useState<number>(0);
  const [startingDate, setStartingDate] = useState<Date | null>(new Date());
  const [endingDate, setEndingDate] = useState<Date | null>(new Date());

  const getParticipantCount = async () => {
    if (!startingDate || !endingDate) {
      console.log("No dates selected");
      return;
    }

    const response = await trainingApi.getParticipantCount(
      startingDate,
      endingDate
    );
    setParticipantCount(response.count);
  };

  return (
    <div>
      <h1>Résztvevők száma: {participantCount}</h1>
      <DatePicker
        label="Kezdő dátum"
        value={startingDate}
        style={{ maxWidth: "20rem", margin: "auto" }}
        onChange={(date) => setStartingDate(date)}
      />
      <DatePicker
        label="Végdátum"
        value={endingDate}
        style={{ maxWidth: "20rem", margin: "auto", marginTop: "1rem" }}
        onChange={(date) => setEndingDate(date)}
      />

      <Button
        style={{ marginTop: "2rem" }}
        onClick={() => getParticipantCount()}
      >
        Lekérdezés
      </Button>
    </div>
  );
};
