import { useState } from 'react';
import { Stepper, Button, Group, TextInput, Select, Table } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Container } from 'react-bootstrap';
import { NewPlayerInput } from '../api/Descriptors';
import { getPositions } from '../util/position';
import { useNavigate } from 'react-router';
import { usePlayers } from '../hooks/usePlayers';

export const NewPlayerView = () => {
    const {useCreateNewPlayer} = usePlayers() 
    const { mutateAsync: createNewPlayer } = useCreateNewPlayer()
    const positions = getPositions({ includeUnknown: false });
    const [active, setActive] = useState(0);
    const isValidTrainingStrength = (value: Number) => {
        // should be a number between 0 and 12
        const number = Number(value);
        return (number >= 0.0 && number <= 12.0) || number;
    }
    const navigate = useNavigate()

    const getPositionLabel = (value: Number | string) => {
        if (typeof value === "number") {
            value = value.toString();
        }
        return positions.find((position) => position.value === value)?.label;
    }

    const form = useForm({
        initialValues: {
            fname: '',
            lname: '',
            primaryPosition: 1,
            secondaryPosition: 2,
            trainingStrength: 5.0,
        },

        validate: (values) => {
            if (active === 1) {
                return {
                    primaryPosition: values.primaryPosition < 1 || values.primaryPosition > 4 ? 'Érvénytelen pozíció' : null,
                    secondaryPosition: (values.secondaryPosition < 1 || values.secondaryPosition > 4) || values.primaryPosition === values.secondaryPosition ? 'Két különböző pozíció szükséges' : null,
                    trainingStrength: !isValidTrainingStrength(values.trainingStrength) ? 'Az erősségnek 0 és 12 közé eső számnak kell esnie' : null,
                };
            }

            return {};
        },
    });

    const nextStep = () =>
        setActive((current) => {
            if (form.validate().hasErrors) {
                return current;
            }
            return current < 2 ? current + 1 : current;
        });

    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    const createUser = async () => {
        const primPos = Number(form.values.primaryPosition)
        const secPos = Number(form.values.secondaryPosition)
        const strength = Number(form.values.trainingStrength.toString());

        const newPlayerInput: NewPlayerInput = {
            fname: form.values.fname,
            lname: form.values.lname,
            primary_position: primPos,
            secondary_position: secPos,
            training_strength: strength,
            status: "active"
        }

        const response = await createNewPlayer(newPlayerInput)
        if (response.status === "success") {
            navigate(`/players/${response.player_id}`)
        }
    }

    return (
        <>
            <Container>
                <Stepper active={active} breakpoint="sm">
                    <Stepper.Step label="Név" description="Személyi adatok">
                        <TextInput label="Vezetéknév" placeholder="Gipsz" {...form.getInputProps('lname')} />
                        <TextInput label="Keresztnév" placeholder="Jakab" {...form.getInputProps('fname')} />
                    </Stepper.Step>

                    <Stepper.Step label="Játékos adatok" description="Edzéshez kapcsolódó adatok">
                        <Select data={positions} label="Elsődleges pozíció" {...form.getInputProps('primaryPosition')} placeholder={positions[0].label} />
                        <Select data={positions} label="Másodlagos pozíció" {...form.getInputProps('secondaryPosition')} placeholder={positions[1].label} />
                        <TextInput mt="md" label="Erősség" placeholder="5.0" {...form.getInputProps('trainingStrength')} />
                    </Stepper.Step>

                    <Stepper.Completed>
                        <Container>
                            <Table striped withBorder withColumnBorders>
                                <tbody>
                                    <tr>
                                        <th>Név</th>
                                        <td>{form.values.lname} {form.values.fname}</td>
                                    </tr>
                                    <tr>
                                        <th>Elsődleges pozíció</th>
                                        <td> {getPositionLabel(form.values.primaryPosition)} </td>
                                    </tr>
                                    <tr>
                                        <th>Másodlagos pozíció</th>
                                        <td> {getPositionLabel(form.values.secondaryPosition)} </td>
                                    </tr>
                                    <tr>
                                        <th>Erősség</th>
                                        <td> {form.values.trainingStrength} </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Container>
                    </Stepper.Completed>
                </Stepper>

                <Group position="right" mt="xl">
                    {(active !== 0) && (
                        <Button variant="default" onClick={prevStep}>
                            Vissza
                        </Button>
                    )}
                    {(active !== 2) && <Button onClick={nextStep}>Következő</Button>}
                    {(active === 2) && <Button onClick={createUser}>Mentés</Button>}
                </Group>
            </Container>
        </>
    );
}

